import { connect } from 'react-redux';
import EventReportUpload from './EventReportUpload';
import { getCreateEventReport, getListEventReports } from '../../store/event/actions';

const mapStateToProps = ({
  eventReducer
}) => ({
  listDataReports: eventReducer.listDataReports,
  staticData: eventReducer.staticData,
  selectEvent: eventReducer.selectEvent
});
const mapDispatchToProps = {
  getListEventReports,
  getCreateEventReport
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventReportUpload);
