import React from 'react';
import FormCreatingOrEditOrganization from '../../components/FormCreatingOrEditOrganization';

const CreatingOrganization = () => (
  <div className="dd-container">
    <div className="dd-container-main">
      <h2>Создание организации</h2>
      <FormCreatingOrEditOrganization />
    </div>
  </div>
);

export default CreatingOrganization;
