import { connect } from 'react-redux';
import FormCreatingOrEditOrganization from './FormCreatingOrEditOrganization';
import {
  createOrganization,
  getInfoOrganization,
  editInfoOrganization
} from '../../store/organization/actions';

const mapStateToProps = ({
  organizationReducer
}) => ({
  infoData: organizationReducer.infoData,
  selectOrganization: organizationReducer.selectOrganization
});
const mapDispatchToProps = {
  createOrganization,
  getInfoOrganization,
  editInfoOrganization
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormCreatingOrEditOrganization);
