import { connect } from 'react-redux';
import DeleteEventUser from './DeleteEventUser';
import { deleteUserOnEvent } from '../../store/event/actions';

const mapStateToProps = () => {};
const mapDispatchToProps = {
  deleteUserOnEvent
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteEventUser);
