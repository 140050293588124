import { connect } from 'react-redux';
import ListUsers from './ListUsers';
import { getListAdmins } from '../../store/roles/actions';

const mapStateToProps = ({
  rolesReducer
}) => ({
  data: rolesReducer.admins.data,
  pagination: rolesReducer.admins.pagination
});
const mapDispatchToProps = {
  getListAdmins
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListUsers);
