import React, { useCallback } from 'react';
import { Button } from 'antd';
import style from './userlayout.module.scss';

const UserLayout = ({
  userReducer
}) => {
  const logout = useCallback(() => {
    localStorage.removeItem('Authorization');
    document.location.href = '/';
  }, []);
  return (
    <div className={style.container}>
      <div className={style.containerUser}>
        <span className="fio">
          {userReducer && userReducer.firstName
            && `${userReducer.firstName} ${userReducer.lastName}`}
        </span>
        <span className="role">
          {userReducer && userReducer.role.title}
        </span>
      </div>
      <Button type="text"
              shape="circle"
              size="large"
              icon={<img src="/images/logout.svg" alt="Выход" />}
              onClick={logout}
      />
    </div>
  );
};

export default UserLayout;
