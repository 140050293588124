import { connect } from 'react-redux';
import ReportUploadAndQuickStatistics from './ReportUploadAndQuickStatistics';
import {
  getStaticOrganization,
  getListOrganizationReports,
  getCreateOrganizationReport
} from '../../store/organization/actions';

const mapStateToProps = ({
  organizationReducer
}) => ({
  listDataReports: organizationReducer.listDataReports,
  staticData: organizationReducer.staticData,
  selectOrganization: organizationReducer.selectOrganization
});
const mapDispatchToProps = {
  getStaticOrganization,
  getListOrganizationReports,
  getCreateOrganizationReport
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportUploadAndQuickStatistics);
