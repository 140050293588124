import React, {
  useEffect,
  useCallback,
  useState,
  useMemo
} from 'react';
import {
  Select,
  Row,
  Col,
  Space,
  Form,
  InputNumber,
  DatePicker,
  Button,
  Modal
} from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import moment from 'moment';
import TabReasons from '../../components/TabReasons';
import style from './votingmanagement.module.scss';

const { confirm } = Modal;
const { RangePicker } = DatePicker;

const VotingManagement = ({
  listData,
  getListOrganizations,
  setSelectOrganizationOnVote,
  staticData,
  setStartOrUpdateInternalVote,
  selectOrganization,
  setStopInternalVote
}) => {
  const [reasons, setReasons] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    if (staticData) {
      setReasons(staticData.reasons);
      form.setFieldsValue({
        ...staticData,
        date: [
          staticData.beginAt && moment(staticData.beginAt),
          staticData.endAt && moment(staticData.endAt)
        ]
      });
    }
  }, [form, staticData]);

  useEffect(() => {
    getListOrganizations();
  }, [getListOrganizations]);
  const onChangeOrganize = useCallback((value) => {
    setSelectOrganizationOnVote(value);
  }, [setSelectOrganizationOnVote]);

  const voteStart = useMemo(() => {
    if (staticData && staticData.endAt) {
      const endAt = moment(staticData.endAt);
      const nowDate = moment(new Date());
      return nowDate.diff(endAt, 'days') >= 0;
    }
    return true;
  }, [staticData]);

  const submitStart = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        setStartOrUpdateInternalVote({
          organizationUuid: selectOrganization,
          countLike: values.countLike,
          beginAt: values.date && values.date[0] && values.date[0].valueOf(),
          endAt: values.date && values.date[1] && values.date[1].valueOf(),
          reasons
        });
      });
  }, [
    form,
    reasons,
    selectOrganization,
    setStartOrUpdateInternalVote
  ]);
  const onClickStop = useCallback(() => {
    confirm({
      title: 'Остановить голосование?',
      onOk() {
        setStopInternalVote({
          organizationUuid: selectOrganization
        });
      },
      onCancel() {

      }
    });
  }, [selectOrganization, setStopInternalVote]);

  return (
    <div className="dd-container">
      <div className={`dd-container-main ${style.voteContainers}`}>
        <div>
          <h2>Управление голосованием</h2>
          <Row gutter={[40, 8]}>
            <Col md={24} lg={12}>
              <Select style={{ width: '100%' }}
                      placeholder="Выберите организацию"
                      fieldNames={{ label: 'title', value: 'uuid' }}
                      options={listData}
                      onChange={onChangeOrganize}
                      value={selectOrganization}
              />
            </Col>
          </Row>
        </div>
        <Form name="basic"
              layout="vertical"
              form={form}
              autoComplete="off"
              requiredMark={false}
              className={style.form}
        >
          <Row>
            <Space size={10}>
              <Form.Item label="Период голосования"
                         name="date"
                         rules={[
                           {
                             required: true,
                             message: 'Введите период голосования'
                           }
                         ]}
              >
                <RangePicker format="DD.MM.YYYY"
                             disabled={!selectOrganization}
                             locale={locale}
                />
              </Form.Item>
              <Form.Item label="Голоса"
                         name="countLike"
                         rules={[
                           {
                             required: true,
                             message: 'Введите голоса'
                           }
                         ]}
              >
                <InputNumber disabled={!selectOrganization} />
              </Form.Item>
            </Space>
          </Row>
          <Row className={style.voteMain}>
            <TabReasons tagsData={reasons || []}
                        setReasons={setReasons}
                        selectOrganization={selectOrganization}
                        voteStart={voteStart}
                        voteForm={form}
                        setStartOrUpdateInternalVote={setStartOrUpdateInternalVote}
            />
          </Row>
          <Row className={style.voteButton}>
            <Space style={{ marginBottom: 16 }}>
              {
                voteStart ? (
                  <Form.Item>
                    <Button type="primary"
                            htmlType="submit"
                            onClick={submitStart}
                            disabled={!selectOrganization}
                    >
                      Начать голосование
                    </Button>
                  </Form.Item>
                ) : (
                  <Form.Item>
                    <Button type="primary"
                            onClick={onClickStop}
                            disabled={!selectOrganization}
                    >
                      Остановить голосование
                    </Button>
                  </Form.Item>
                )
              }
              <Form.Item>
                <Button type="dashed"
                        disabled={!selectOrganization}
                >
                  Очистить
                </Button>
              </Form.Item>
            </Space>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default VotingManagement;
