import { connect } from 'react-redux';
import FormCreatingOrEditPerson from './FormCreatingOrEditPerson';
import {
  createPersonnelOnOrganization,
  deletePersonnelOnOrganization,
  editPersonnelOnOrganization,
  closeModalCreatePerson
} from '../../store/personnel/actions';

const mapStateToProps = ({
  personnelReducer: {
    selectOrganization,
    isModalVisible,
    isModalVisibleCreatePerson
  }
}) => ({
  selectOrganization,
  isModalVisible,
  isModalVisibleCreatePerson
});
const mapDispatchToProps = {
  createPersonnelOnOrganization,
  editPersonnelOnOrganization,
  deletePersonnelOnOrganization,
  closeModalCreatePerson
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormCreatingOrEditPerson);
