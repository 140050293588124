import { connect } from 'react-redux';
import ModalFormCreatingOrEditPerson from './ModalFormCreatingOrEditPerson';
import { closeModalCreatePerson } from '../../store/personnel/actions';

const mapStateToProps = ({
  personnelReducer: {
    isModalVisibleCreatePerson
  }
}) => ({
  isModalVisibleCreatePerson
});
const mapDispatchToProps = {
  closeModalCreatePerson
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalFormCreatingOrEditPerson);
