import { connect } from 'react-redux';
import VotingManagement from './VotingManagement';
import {
  getListOrganizations
} from '../../store/organization/actions';
import {
  setSelectOrganizationOnVote,
  setStartOrUpdateInternalVote,
  setStopInternalVote
} from '../../store/vote/actions';

const mapStateToProps = ({
  organizationReducer: {
    listData
  },
  voteReducer: {
    staticData,
    selectOrganization
  }
}) => ({
  listData,
  staticData,
  selectOrganization
});
const mapDispatchToProps = {
  getListOrganizations,
  setSelectOrganizationOnVote,
  setStartOrUpdateInternalVote,
  setStopInternalVote
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VotingManagement);
