import React, {
  useCallback
} from 'react';
import { Button } from 'antd';
import style from './buttonuserunassigned.module.scss';

const ButtonUserUnassigned = ({
  modalUserUnassignedOpen,
  record
}) => {
  const onClick = useCallback(() => {
    modalUserUnassignedOpen({
      userUuid: record.uuid
    });
  }, [modalUserUnassignedOpen, record]);
  return (
    <Button type="link"
            onClick={onClick}
            className={style.button}
    >
      Выбрать
    </Button>
  );
};

export default ButtonUserUnassigned;
