import * as actionTypes from './types';

const initialState = {
  selectOrganization: null,
  isModalVisible: false,
  isModalVisibleCreatePerson: false,
  persons: {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      search: null
    }
  },
  personsDop: {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      search: null
    }
  }
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_SELECT_ORGANIZATION_ON_PERSONNEL:
      return {
        ...state,
        selectOrganization: payload
      };
    case actionTypes.GET_LIST_PERSONNEL_ON_ORGANIZATION_SUCCESS:
      return {
        ...state,
        persons: {
          ...state.persons,
          data: payload.rows,
          pagination: {
            ...state.persons.pagination,
            pageSize: payload.limit,
            current: payload.current,
            total: payload.count,
            search: payload.search
          }
        }
      };
    case actionTypes.GET_LIST_PERSONNEL_ON_ORGANIZATION_DOP_SUCCESS:
      return {
        ...state,
        personsDop: {
          ...state.personsDop,
          data: payload.rows,
          pagination: {
            ...state.personsDop.pagination,
            pageSize: payload.limit,
            current: payload.current,
            total: payload.count,
            search: payload.search
          }
        }
      };
    case actionTypes.OPEN_MODAL_PERSONNEL_ON_ORGANIZATION:
      return {
        ...state,
        isModalVisible: true
      };
    case actionTypes.CLOSE_MODAL_PERSONNEL_ON_ORGANIZATION:
      return {
        ...state,
        isModalVisible: false
      };
    case actionTypes.OPEN_MODAL_CREATE_PERSON:
      return {
        ...state,
        isModalVisibleCreatePerson: true
      };
    case actionTypes.CLOSE_MODAL_CREATE_PERSON:
      return {
        ...state,
        isModalVisibleCreatePerson: false
      };
    case actionTypes.ACCEPT_ALL_PERSONS_IN_ORGANIZATION:
      return {
        ...state
      };
    default:
      return state;
  }
};

export default reducer;
