import { connect } from 'react-redux';
import TablePerson from './TablePerson';
import {
  getListPersonnelOnOrganization,
  getListPersonnelOnOrganizationDop
} from '../../store/personnel/actions';

const mapStateToProps = ({
  personnelReducer
}) => ({
  data: personnelReducer.persons.data,
  pagination: personnelReducer.persons.pagination,
  dataDop: personnelReducer.personsDop.data,
  paginationDop: personnelReducer.personsDop.pagination,
  organizationUuid: personnelReducer.selectOrganization
});
const mapDispatchToProps = {
  getListPersonnelOnOrganization,
  getListPersonnelOnOrganizationDop
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TablePerson);
