import React from 'react';
import CreatingOrganization from '../routes/CreatingOrganization';
import EventManagement from '../routes/EventManagement';
import OrganizationManagement from '../routes/OrganizationManagement';
import PersonnelManagement from '../routes/PersonnelManagement';
import VotingManagement from '../routes/VotingManagement';
import RoleManagement from '../routes/RoleManagement';
import UnassignedUsers from '../routes/UnassignedUsers';

export const listMenu = {
  OWNER: [
    {
      link: 'creating-organization',
      text: 'Создание организации',
      component: CreatingOrganization
    },
    {
      link: 'organization-management',
      text: 'Управление организацией',
      component: OrganizationManagement
    },
    {
      link: 'personnel-management',
      text: 'Управление персоналом',
      component: PersonnelManagement,
      disable: false
    },
    {
      link: 'voting-management',
      text: 'Управление голосованием',
      component: VotingManagement
    },
    {
      link: 'event-management',
      text: 'Управление мероприятиями',
      component: EventManagement,
      disable: false
    },
    {
      link: 'role-management',
      text: 'Управление ролями',
      component: RoleManagement,
      disable: false
    },
    {
      link: 'unassigned-users',
      text: 'Нераспределённые пользователи',
      component: UnassignedUsers
    }
  ],
  ADMIN: [
    {
      link: 'creating-organization',
      text: 'Создание организации',
      component: null,
      disable: true
    },
    {
      link: 'organization-management',
      text: 'Управление организацией',
      component: OrganizationManagement
    },
    {
      link: 'personnel-management',
      text: 'Управление персоналом',
      component: PersonnelManagement
    },
    {
      link: 'voting-management',
      text: 'Управление голосованием',
      component: null,
      disable: true
    },
    {
      link: 'event-management',
      text: 'Управление мероприятиями',
      component: EventManagement,
      disable: false
    },
    {
      link: 'role-management',
      text: 'Управление ролями',
      component: null,
      disable: true
    },
    {
      link: 'unassigned-users',
      text: 'Нераспределённые пользователи',
      component: null,
      disable: true
    }
  ]
};
