import { connect } from 'react-redux';
import RenderRoute from './RenderRoute';

const mapStateToProps = ({
  userReducer: {
    role
  }
}) => ({
  role
});

export default connect(
  mapStateToProps,
  null
)(RenderRoute);
