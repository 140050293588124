import { connect } from 'react-redux';
import Layout from './Layout';
import { userCheck } from '../../store/user/actions';

const mapStateToProps = ({
  userReducer,
  loaderReducer
}) => ({
  userReducer,
  loaderReducer
});
const mapDispatchToProps = {
  userCheck
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout);
