export const mocksURLs = [
  {
    url: 'api/configuring/inOutInfo/',
    name: 'INOUTINFO',
    param: 'puoId',
    module: 'configureProduct'
  }
];

export const getMocksByURL = (
  {
    name, param, url, importLink, module
  },
  mockUrl
) => {
  const paramValue = mockUrl.split(url).find((item) => +item > 0);
  if (module) {
    return import(`../store/mock/${module}`).then((mock) => {
      if (mock[module] && mock[module][name]) {
        if (param && paramValue) {
        return mock[module][name].filter((el) => el[param] === +paramValue);
      }
        return mock[module][name];
      } return [];
    });
  }
  return [];
};
