import {
  all,
  call,
  delay,
  put,
  takeEvery,
  select
} from 'redux-saga/effects';
import * as actions from './actions';
import * as actionTypes from './types';
import {
  get,
  put as axiosPut,
  post,
  apiDelete
} from '../../utils/api';
import { popupLoaderClose, popupLoaderOpen } from '../loader/actions';
import { errorModal, openNotificationWithIcon } from '../../utils/function';
import {
  EMPLOYEES,
  EMPLOYEE,
  EMPLOYEE_ON_USER,
  IMPORT_PERSON_ON_CSV,
  ORGANIZATION_ON_UUID_ACCEPT_ALL
} from '../../utils/url';

function* getListPersonnelOnOrganization({ payload }) {
  if (payload.isSearch) {
    yield put(popupLoaderClose());
    yield delay(1000);
  }
  try {
    yield put(popupLoaderOpen());
    let response = yield call(
      get,
      // eslint-disable-next-line max-len
      `${EMPLOYEES(payload.organizationUuid)}?offset=${payload.offset}&limit=${payload.limit}${payload.search ? `&search=${encodeURI(payload.search)}` : ''}`
    );
    let offset = payload?.offset ? payload?.offset : 0;
    if (response?.rows && response?.rows.length === 0 && payload.offset > 0) {
      offset -= payload.limit;
      response = yield call(
        get,
        // eslint-disable-next-line max-len
        `${EMPLOYEES(payload.organizationUuid)}?offset=${offset}&limit=${payload.limit}${payload.search ? `&search=${encodeURI(payload.search)}` : ''}`
      );
    }
    yield put(actions.getListPersonnelOnOrganizationSuccess({
      ...response,
      limit: payload.limit,
      search: payload.search,
      current: Math.round(offset / payload.limit) + 1
    }));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* getListPersonnelOnOrganizationDop({ payload }) {
  if (payload.isSearch) {
    yield put(popupLoaderClose());
    yield delay(1000);
  }
  try {
    yield put(popupLoaderOpen());
    let response = yield call(
      get,
      // eslint-disable-next-line max-len
      `${EMPLOYEES(payload.organizationUuid)}?offset=${payload.offset}&limit=${payload.limit}${payload.search ? `&search=${encodeURI(payload.search)}` : ''}`
    );
    let offset = payload?.offset ? payload?.offset : 0;
    if (response?.rows && response?.rows.length === 0 && payload.offset > 0) {
      offset -= payload.limit;
      response = yield call(
        get,
        // eslint-disable-next-line max-len
        `${EMPLOYEES(payload.organizationUuid)}?offset=${offset}&limit=${payload.limit}${payload.search ? `&search=${encodeURI(payload.search)}` : ''}`
      );
    }
    yield put(actions.getListPersonnelOnOrganizationDopSuccess({
      ...response,
      limit: payload.limit,
      search: payload.search,
      current: Math.round(offset / payload.limit) + 1
    }));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* updateListPersonnelOnOrganization({ organizationUuid }) {
  const { persons } = yield select(({ personnelReducer }) => personnelReducer);
  yield put(actions.getListPersonnelOnOrganization({
    search: persons.pagination.search && persons.pagination.search.length >= 3,
    offset: ((persons.pagination.current ? persons.pagination.current : 1) - 1) * persons.pagination.pageSize,
    limit: persons.pagination.pageSize,
    organizationUuid
  }));
}
function* updateListPersonnelOnOrganizationDop({ organizationUuid }) {
  const { personsDop } = yield select(({ personnelReducer }) => personnelReducer);
  yield put(actions.getListPersonnelOnOrganizationDop({
    search: personsDop.pagination.search && personsDop.pagination.search.length >= 3,
    offset: ((personsDop.pagination.current ? personsDop.pagination.current : 1) - 1) * personsDop.pagination.pageSize,
    limit: personsDop.pagination.pageSize,
    organizationUuid
  }));
}

/**
 * {
 *   "lastName": "Катамаранов",
 *   "firstName": "Игорь",
 *   "middleName": "Натальевич",
 *   "gender": "Male",
 *   "birthDate": 1633355410406,
 *   "personalPhone": "79998887766",
 *   "personalEmail": "inkatamaranov@qm.ru",
 *   "personalId": null,
 *   "employee": {
 *     "workPhone": "8 (800) 453-33-22, доб. 112",
 *     "position": "Инженер по качеству",
 *     "department": "Отдел управления качеством",
 *     "departmentTwo": null,
 *     "departmentThree": null,
 *     "status": "Сотрудник",
 *     "speciality": "Мерник",
 *     "employmentDate": 1633355410406,
 *     "achievements": "Измерил неизмеримое",
 *     "merits": "Сломал измеритель и не только..."
 *   }
 * }
 * */
function* createPersonnelOnOrganization({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      post,
      EMPLOYEE(payload.organizationUuid),
      {
        lastName: payload.lastName ? payload.lastName : null,
        firstName: payload.firstName ? payload.firstName : null,
        middleName: payload.middleName ? payload.middleName : null,
        gender: payload.gender ? payload.gender : null,
        birthDate: payload.birthDate ? payload.birthDate : null,
        personalPhone: payload.personalPhone ? payload.personalPhone : null,
        personalEmail: payload.personalEmail ? payload.personalEmail : null,
        personalId: payload.personalId ? payload.personalId : null,
        employee: {
          workPhone: payload.workPhone ? payload.workPhone : null,
          position: payload.position ? payload.position : null,
          department: payload.department ? payload.department : null,
          departmentTwo: payload.departmentTwo ? payload.departmentTwo : null,
          departmentThree: payload.departmentThree ? payload.departmentThree : null,
          status: payload.status ? payload.status : null,
          speciality: payload.speciality ? payload.speciality : null,
          employmentDate: payload.employmentDate ? payload.employmentDate : null,
          dismissalDate: payload.dismissalDate ? payload.dismissalDate : null,
          achievements: payload.achievements ? payload.achievements : null,
          merits: payload.merits ? payload.merits : null,
          acceptedAt: payload.acceptedAt
        }
      }
    );
    yield put(popupLoaderClose());
    openNotificationWithIcon(
      'success',
      'Пользователь создан',
      null
    );
    yield all([
      updateListPersonnelOnOrganization({ organizationUuid: payload.organizationUuid }),
      updateListPersonnelOnOrganizationDop({ organizationUuid: payload.organizationUuid })
    ]);
    yield put(actions.closeModalCreatePerson());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}
function* editPersonnelOnOrganization({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      axiosPut,
      EMPLOYEE_ON_USER(payload.userUuid, payload.organizationUuid),
      {
        lastName: payload.lastName ? payload.lastName : null,
        firstName: payload.firstName ? payload.firstName : null,
        middleName: payload.middleName ? payload.middleName : null,
        gender: payload.gender ? payload.gender : null,
        birthDate: payload.birthDate ? payload.birthDate : null,
        personalPhone: payload.personalPhone ? payload.personalPhone : null,
        personalEmail: payload.personalEmail ? payload.personalEmail : null,
        personalId: payload.personalId ? payload.personalId : null,
        employee: {
          workPhone: payload.workPhone ? payload.workPhone : null,
          position: payload.position ? payload.position : null,
          department: payload.department ? payload.department : null,
          departmentTwo: payload.departmentTwo ? payload.departmentTwo : null,
          departmentThree: payload.departmentThree ? payload.departmentThree : null,
          status: payload.status ? payload.status : null,
          speciality: payload.speciality ? payload.speciality : null,
          employmentDate: payload.employmentDate ? payload.employmentDate : null,
          dismissalDate: payload.dismissalDate ? payload.dismissalDate : null,
          achievements: payload.achievements ? payload.achievements : null,
          merits: payload.merits ? payload.merits : null,
          acceptedAt: payload.acceptedAt
        }
      }
    );
    yield put(popupLoaderClose());
    openNotificationWithIcon(
      'success',
      'Данные обновлены',
      null
    );
    if (payload.setKeyRowExpanded) {
      payload.setKeyRowExpanded((values) => {
        let temp = values;
        if (temp.indexOf(payload.userUuid) === -1) {
          temp.push(payload.userUuid);
        } else {
          temp = temp.filter((item) => item !== payload.userUuid);
        }
        return temp;
      });
    }
    yield all([
      updateListPersonnelOnOrganization({ organizationUuid: payload.organizationUuid }),
      updateListPersonnelOnOrganizationDop({ organizationUuid: payload.organizationUuid })
    ]);
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}
function* deletePersonnelOnOrganization({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      apiDelete,
      EMPLOYEE_ON_USER(payload.userUuid, payload.organizationUuid)
    );
    yield put(popupLoaderClose());
    openNotificationWithIcon(
      'success',
      'Пользователь успешно удален',
      null
    );
    yield all([
      updateListPersonnelOnOrganization({ organizationUuid: payload.organizationUuid }),
      updateListPersonnelOnOrganizationDop({ organizationUuid: payload.organizationUuid })
    ]);
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* acceptAllPersonsInOrganization({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      post,
      ORGANIZATION_ON_UUID_ACCEPT_ALL(payload.organizationUuid)
    );
    yield put(popupLoaderClose());
    openNotificationWithIcon(
      'success',
      'Пользователи успешно акцептованы',
      null
    );
    yield all([
      updateListPersonnelOnOrganization({ organizationUuid: payload.organizationUuid }),
      updateListPersonnelOnOrganizationDop({ organizationUuid: payload.organizationUuid })
    ]);
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* importPersonUploadCsvFile({ payload }) {
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      post,
      `${IMPORT_PERSON_ON_CSV}/${payload.organizationUUID}`,
      payload.file,
      {
        headers: {
          'Content-Type': 'application/octet-stream',
          accept: 'application/json'
        }
      }
    );
    let temp = true;
    let i = 0;
    while (temp && i < 30) {
      yield delay(response.retryAfter * 1000);
      try {
        const responseStatus = yield call(
          get,
          `${IMPORT_PERSON_ON_CSV}/${payload.organizationUUID}/data/${response.importUUID}`
        );
        yield put(popupLoaderClose());
        yield all([
          updateListPersonnelOnOrganization({ organizationUuid: payload.organizationUUID }),
          updateListPersonnelOnOrganizationDop({ organizationUuid: payload.organizationUUID })
        ]);
        openNotificationWithIcon(
          'success',
          'Импорт данных успешно завершен',
          // eslint-disable-next-line react/react-in-jsx-scope
          <span>
            {`игнорировано - ${responseStatus.ignored}`}
            {/* eslint-disable-next-line react/react-in-jsx-scope */}
            <br />
            {`создано - ${responseStatus.created}`}
            {/* eslint-disable-next-line react/react-in-jsx-scope */}
            <br />
            {`обновлено - ${responseStatus.updated}`}
            {/* eslint-disable-next-line react/react-in-jsx-scope */}
            <br />
            {`удалено - ${responseStatus.deleted}`}
            {/* eslint-disable-next-line react/react-in-jsx-scope */}
            <br />
          </span>,
          7
        );
        temp = false;
      } catch (e) {
        if (e?.response?.status !== 425) {
          temp = false;
          yield put(popupLoaderClose());
          errorModal(
            e?.response?.data?.error,
            e?.response?.data?.message,
            e?.response?.data?.data
          );
        }
      }
      i += 1;
    }
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

// eslint-disable-next-line
export default function* () {
  yield all([
    takeEvery(actionTypes.GET_LIST_PERSONNEL_ON_ORGANIZATION, getListPersonnelOnOrganization),
    takeEvery(actionTypes.GET_LIST_PERSONNEL_ON_ORGANIZATION_DOP, getListPersonnelOnOrganizationDop),
    takeEvery(actionTypes.CREATE_PERSONNEL_ON_ORGANIZATION, createPersonnelOnOrganization),
    takeEvery(actionTypes.EDIT_PERSONNEL_ON_ORGANIZATION, editPersonnelOnOrganization),
    takeEvery(actionTypes.DELETE_PERSONNEL_ON_ORGANIZATION, deletePersonnelOnOrganization),
    takeEvery(actionTypes.IMPORT_PERSON_UPLOAD_CSV_FILE, importPersonUploadCsvFile),
    takeEvery(actionTypes.ACCEPT_ALL_PERSONS_IN_ORGANIZATION, acceptAllPersonsInOrganization)
  ]);
}
