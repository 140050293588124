import * as actionTypes from './types';

const initialState = {
  staticData: {},
  selectOrganization: null
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_SELECT_ORGANIZATION_ON_VOTE:
      return {
        ...state,
        selectOrganization: payload
      };
    case actionTypes.GET_STATIC_INTERNAL_VOTE_SUCCESS:
      return {
        ...state,
        staticData: payload
      };
    default:
      return state;
  }
};

export default reducer;
