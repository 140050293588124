import { connect } from 'react-redux';
import Auth from './Auth';
import { auth } from '../../store/auth/actions';
import { userCheck } from '../../store/user/actions';

const mapStateToProps = ({
  userReducer
}) => ({
  userReducer
});
const mapDispatchToProps = {
  auth,
  userCheck
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Auth);
