import { connect } from 'react-redux';
import ButtonResetPassword from './ButtonResetPassword';
import { resetPassword } from '../../store/roles/actions';

const mapStateToProps = () => {};
const mapDispatchToProps = {
  resetPassword
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonResetPassword);
