import React, {
  useCallback,
  useState,
  useMemo,
  useEffect
} from 'react';
import {
  Radio,
  Space,
  Tabs,
  Table,
  Tag, Form
} from 'antd';
import ModalThanks from '../ModalThanks';
import style from './tabevent.module.scss';
import {
  renderDepartment,
  renderFIO,
  renderStatus
} from '../../utils/function';
import ModalCandidates from '../ModalCandidates';
import NotificationManagement from '../NotificationManagement';
import DeleteEventUser from '../DeleteEventUser';

const { TabPane } = Tabs;

const columnsTable = [
  {
    width: 170,
    title: 'ФИО',
    dataIndex: '',
    render: renderFIO
  },
  {
    width: 150,
    title: 'Отдел',
    dataIndex: '',
    render: renderDepartment
  },
  {
    width: 190,
    title: 'Email',
    dataIndex: 'personalEmail'
  },
  {
    width: 170,
    title: 'Тел.моб.',
    dataIndex: 'personalPhone'
  },
  {
    width: 170,
    title: 'Статус',
    dataIndex: '',
    render: renderStatus
  },
  {
    title: '',
    dataIndex: '',
    width: 70,
    fixed: 'right',
    render: (text, record, index) => <DeleteEventUser record={record} />
  }
];
const columnsTableModal = [
  {
    width: 170,
    title: 'ФИО',
    dataIndex: '',
    render: renderFIO
  },
  {
    width: 150,
    title: 'Отдел',
    dataIndex: '',
    render: renderDepartment
  },
  {
    width: 190,
    title: 'Email',
    dataIndex: 'personalEmail'
  },
  {
    width: 170,
    title: 'Тел.моб.',
    dataIndex: 'personalPhone'
  },
  {
    width: 170,
    title: 'Статус',
    dataIndex: '',
    render: renderStatus
  }
];
const dataFilter = {
  offset: 0,
  limit: 10
};
const dataFilterModal = {
  search: null,
  offset: 0,
  limit: 10
};

const TabEvent = ({
  dataSelectEvent,
  voteCandidatesOnEvent,
  voteCandidatesOnOrganization,
  getDataCandidatesEventVoteUuid,
  getDataCandidatesOnOrganizationEventVote,
  getListOrganizations,
  listData,
  setDataEditEvent,
  selectEvent,
  editEvent
}) => {
  const [visibleModalUser, setVisibleModalUser] = useState(false);
  const [visibleModalThanks, setVisibleModalThanks] = useState(false);
  const [organizationUuidOnModal, setOrganizationUuidOnModal] = useState();
  const [searchModal, setSearchModal] = useState();
  const [modalTabsData, setModalTabsData] = useState(
    dataSelectEvent && dataSelectEvent.reasons ? dataSelectEvent.reasons : []
  );
  const [keyTab, setKeyTab] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [form] = Form.useForm();

  /** Получение списка организаций */
  useEffect(() => {
    getListOrganizations();
  }, [getListOrganizations]);

  /** Получение списка кандидатов, по мероприятию */
  useEffect(() => {
    if (selectEvent) {
      getDataCandidatesEventVoteUuid({
        ...dataFilter,
        uuid: selectEvent
      });
    }
  }, [selectEvent, getDataCandidatesEventVoteUuid]);

  /** Поиск в модальном окне */
  useEffect(() => {
    if (visibleModalUser && searchModal && searchModal.length >= 3) {
      getDataCandidatesOnOrganizationEventVote({
        search: searchModal,
        offset: 0,
        limit: voteCandidatesOnOrganization.pagination.pageSize,
        isSearch: true,
        organizationUuid: organizationUuidOnModal
      });
    } else if (visibleModalUser && searchModal && searchModal.length < 3) {
      getDataCandidatesOnOrganizationEventVote({
        search: null,
        offset: 0,
        limit: voteCandidatesOnOrganization.pagination.pageSize,
        isSearch: true,
        organizationUuid: organizationUuidOnModal
      });
    }
  }, [
    getDataCandidatesOnOrganizationEventVote,
    organizationUuidOnModal,
    searchModal,
    visibleModalUser,
    voteCandidatesOnOrganization.pagination.pageSize
  ]);

  useCallback(() => {
    if (visibleModalUser) {
      getDataCandidatesOnOrganizationEventVote({
        ...dataFilterModal,
        organizationUuid: organizationUuidOnModal || null
      });
    }
  }, [getDataCandidatesOnOrganizationEventVote, organizationUuidOnModal, visibleModalUser]);

  /**
   *  Функция при выборе в модальном окне организации
   *  \\ Отправить запрос на получение списка кандидатов \\
   *  \\ Сбросить поиск и пагинацию \\
   *  */
  const onChangeOrganize = useCallback((value) => {
    setOrganizationUuidOnModal(value);
    getDataCandidatesOnOrganizationEventVote({
      ...dataFilterModal,
      organizationUuid: value || null
    });
  }, [getDataCandidatesOnOrganizationEventVote]);

  /** Пагинация по таблице в модальке */
  const handleTableChangeModal = useCallback((pagin, filters, sorter) => {
    getDataCandidatesOnOrganizationEventVote({
      search: searchModal && searchModal.length >= 3,
      offset: (pagin.current - 1) * pagin.pageSize,
      limit: pagin.pageSize,
      organizationUuid: organizationUuidOnModal
    });
  }, [
    getDataCandidatesOnOrganizationEventVote,
    organizationUuidOnModal,
    searchModal
  ]);

  /** Переключения по типу мероприятия */
  const onChangeRadio = useCallback((value) => {
    setDataEditEvent({
      type: value.target.value
    });
  }, [setDataEditEvent]);

  /** Пагинация по таблице */
  const handleTableChange = useCallback((pagin, filters, sorter) => {
    getDataCandidatesEventVoteUuid({
      offset: (pagin.current - 1) * pagin.pageSize,
      limit: pagin.pageSize,
      uuid: dataSelectEvent.uuid
    });
  }, [dataSelectEvent.uuid, getDataCandidatesEventVoteUuid]);

  /** Модальное окно сохранение благодарностей на ок */
  const handleOk = useCallback(() => {
    setDataEditEvent({
      reasons: modalTabsData
    });
    editEvent({
      ...dataSelectEvent,
      reasons: modalTabsData
    });
    setVisibleModalThanks(false);
  }, [dataSelectEvent, editEvent, modalTabsData, setDataEditEvent]);
  const handleCancel = useCallback(() => {
    setVisibleModalThanks(false);
  }, []);
  const handleCancelUser = useCallback(() => {
    setVisibleModalUser(false);
  }, []);
  const onCloseTag = useCallback((e) => {
    setModalTabsData((value) => value.filter((item) => item.title !== e));
  }, []);

  /** Сохранение благодарностей в модальном окне (сохранение в стейт) */
  const handleFormSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        setModalTabsData((items) => {
          const temp = items.slice(0);
          temp.push({ title: values.title });
          return temp;
        });
        form.setFieldsValue({
          title: ''
        });
      });
  }, [form, setModalTabsData]);

  /** Кнопка редактировать */
  const onEditTab = useCallback((targetKey, action) => {
    if (action === 'add' && Number(keyTab) === 2) {
      setModalTabsData(dataSelectEvent && dataSelectEvent.reasons ? dataSelectEvent.reasons : []);
      setVisibleModalThanks(true);
    }
    if (action === 'add' && Number(keyTab) === 1) {
      setVisibleModalUser(true);
    }
  }, [keyTab, dataSelectEvent]);
  const onChangeTab = useCallback((activeKey) => {
    setKeyTab(activeKey);
  }, []);

  /** селект по строкам в модальном окне */
  const onChangeSelectCandidates = useCallback((_selectedRowKeys) => {
    setSelectedRowKeys(_selectedRowKeys);
  }, []);

  /** Кнопка сохранить в модальном окне с кандидатами */
  const onSaveModalCandidates = useCallback(() => {
    setDataEditEvent({
      candidates: selectedRowKeys.map((item) => ({ uuid: item }))
    });
    editEvent({
      ...dataSelectEvent,
      candidates: selectedRowKeys.map((item) => ({ uuid: item }))
    });
    setVisibleModalUser(false);
  }, [dataSelectEvent, editEvent, selectedRowKeys, setDataEditEvent]);

  const renderTable = useMemo(() => (
    <Table columns={columnsTable}
           rowKey={(record) => record.uuid}
           dataSource={voteCandidatesOnEvent.data}
           scroll={{ y: '100%' }}
           pagination={voteCandidatesOnEvent.pagination}
           onChange={handleTableChange}
    />
  ), [
    voteCandidatesOnEvent.data,
    voteCandidatesOnEvent.pagination,
    handleTableChange
  ]);

  return (
    <div className={style.container}>
      <div>
        <Space>
          <span>Тип мероприятия:</span>
          <Radio.Group onChange={onChangeRadio} value={dataSelectEvent?.type}>
            <Radio value="OPEN">Открытый</Radio>
            <Radio value="CLOSE">Закрытый</Radio>
          </Radio.Group>
        </Space>
      </div>
      <div className={style.main}>
        <Tabs type="editable-card"
              addIcon={<img src="/images/edit.svg" alt="Редактировать" />}
              onEdit={onEditTab}
              onChange={onChangeTab}
        >
          <TabPane tab="Кандидаты" key={1} closable={false}>
            <div className="dd-tab">
              <div className="dd-table">
                { renderTable }
              </div>
            </div>
            <ModalCandidates visible={visibleModalUser}
                             handleCancel={handleCancelUser}
                             columns={columnsTableModal}
                             listDataOrganization={listData}
                             onChangeOrganize={onChangeOrganize}
                             data={voteCandidatesOnOrganization.data}
                             pagination={voteCandidatesOnOrganization.pagination}
                             handleTableChangeModal={handleTableChangeModal}
                             searchModal={searchModal}
                             setSearchModal={setSearchModal}
                             onChangeSelectCandidates={onChangeSelectCandidates}
                             selectedRowKeys={selectedRowKeys}
                             onSave={onSaveModalCandidates}
            />
          </TabPane>
          <TabPane tab="Благодарности" key={2} closable={false}>
            <div className="dd-tab">
              <div className={style.tag}>
                {
                  dataSelectEvent?.reasons.map((item) => (
                    <Tag key={`tabReasons_${item.title}`}>{ item.title }</Tag>
                  ))
                }
              </div>
            </div>
            <ModalThanks visible={visibleModalThanks}
                         form={form}
                         handleOk={handleOk}
                         handleCancel={handleCancel}
                         handleFormSubmit={handleFormSubmit}
                         modalTabsData={modalTabsData}
                         onCloseTag={onCloseTag}
            />
          </TabPane>
          <TabPane tab="Уведомления" key={3} closable={false}>
            <div className="dd-tab">
              <div style={{ padding: 20 }}>
                <NotificationManagement isEvent />
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default TabEvent;
