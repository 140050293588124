import { connect } from 'react-redux';
import ModalUserUnassigned from './ModalUserUnassigned';
import {
  getListUnassignedUsersOrganizations,
  modalUserUnassignedClose,
  operationUnassignedUserOnOrganization
} from '../../store/unassignedUsers/actions';

const mapStateToProps = ({
  unassignedUsersReducer: {
    modal: {
      isModalVisible,
      userUuid,
      organizations: {
        data,
        pagination
      }
    }
  }
}) => ({
  isModalVisible,
  userUuid,
  data,
  pagination
});
const mapDispatchToProps = {
  modalUserUnassignedClose,
  getListUnassignedUsersOrganizations,
  operationUnassignedUserOnOrganization
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalUserUnassigned);
