import React, { useCallback } from 'react';
import {
  Button,
  Modal
} from 'antd';
import style from './modallistpersononorganization.module.scss';
import TablePerson from '../TablePerson';

const ModalListPersonOnOrganization = ({
  isModalVisible,
  closeModalPersonnelOnOrganization
}) => {
  const handleCancel = useCallback(() => {
    closeModalPersonnelOnOrganization();
  }, [closeModalPersonnelOnOrganization]);
  return isModalVisible && (
    <Modal visible={isModalVisible}
           closable={false}
           footer={[
             <Button onClick={handleCancel}>
               Закрыть
             </Button>
           ]}
           wrapClassName={style.modal}
    >
      <TablePerson isModal isModalVisible={isModalVisible} key="table_person_2" />
    </Modal>
  );
};

export default React.memo(ModalListPersonOnOrganization);
