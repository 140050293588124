import * as actionsTypes from './types';

export function getListUnassignedUsers(payload) {
  return {
    type: actionsTypes.GET_LIST_UNASSIGNED_USERS,
    payload
  };
}
export function getListUnassignedUsersSuccess(payload) {
  return {
    type: actionsTypes.GET_LIST_UNASSIGNED_USERS_SUCCESS,
    payload
  };
}
export function getListUnassignedUsersFail(payload) {
  return {
    type: actionsTypes.GET_LIST_UNASSIGNED_USERS_FAIL,
    payload
  };
}

export function modalUserUnassignedOpen(payload) {
  return {
    type: actionsTypes.MODAL_USER_UNASSIGNED_OPEN,
    payload
  };
}
export function modalUserUnassignedClose(payload) {
  return {
    type: actionsTypes.MODAL_USER_UNASSIGNED_CLOSE,
    payload
  };
}

export function deleteUnassignedUser(payload) {
  return {
    type: actionsTypes.DELETE_UNASSIGNED_USER,
    payload
  };
}

export function getListUnassignedUsersOrganizations(payload) {
  return {
    type: actionsTypes.GET_LIST_UNASSIGNED_USERS_ORGANIZATIONS,
    payload
  };
}
export function getListUnassignedUsersOrganizationsSuccess(payload) {
  return {
    type: actionsTypes.GET_LIST_UNASSIGNED_USERS_ORGANIZATIONS_SUCCESS,
    payload
  };
}
export function getListUnassignedUsersOrganizationsFail(payload) {
  return {
    type: actionsTypes.GET_LIST_UNASSIGNED_USERS_ORGANIZATIONS_FAIL,
    payload
  };
}

export function operationUnassignedUserOnOrganization(payload) {
  return {
    type: actionsTypes.OPERATION_UNASSIGNED_USER_ON_ORGANIZATION,
    payload
  };
}
