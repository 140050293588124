import { all } from 'redux-saga/effects';
import { authSagas } from './auth';
import { userSagas } from './user';
import { organizationSagas } from './organization';
import { unassignedUsersSagas } from './unassignedUsers';
import { voteSagas } from './vote';
import { personnelSagas } from './personnel';
import { eventSagas } from './event';
import { rolesSagas } from './roles';

export default function* rootSagas() {
  yield all([
    authSagas(),
    userSagas(),
    organizationSagas(),
    unassignedUsersSagas(),
    voteSagas(),
    personnelSagas(),
    eventSagas(),
    rolesSagas()
  ]);
}
