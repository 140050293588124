import React from 'react';
import { Layout as HeaderAnt } from 'antd';
import style from './header.module.scss';
import UserLayout from '../UserLayout';

const Header = ({
  isUser
}) => (
  <HeaderAnt className={style.header}>
    <div className={style.logo}>
      <img src="/logo.svg" alt="3D коммуникации" />
      <span>3D коммуникации</span>
    </div>
    { isUser && <UserLayout /> }
  </HeaderAnt>
);

export default Header;
