import { connect } from 'react-redux';
import DeleteOrganization from './DeleteOrganization';
import {
  deleteInfoOrganization
} from '../../store/organization/actions';

const mapStateToProps = ({
  organizationReducer
}) => ({
  selectOrganization: organizationReducer.selectOrganization
});
const mapDispatchToProps = {
  deleteInfoOrganization
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteOrganization);
