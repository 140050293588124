import { connect } from 'react-redux';
import ButtonUserUnassignedDelete from './ButtonUserUnassignedDelete';
import { deleteUnassignedUser } from '../../store/unassignedUsers/actions';

const mapStateToProps = () => {};
const mapDispatchToProps = {
  deleteUnassignedUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonUserUnassignedDelete);
