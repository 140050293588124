import { connect } from 'react-redux';
import ButtonUserUnassigned from './ButtonUserUnassigned';
import { modalUserUnassignedOpen } from '../../store/unassignedUsers/actions';

const mapStateToProps = () => {};
const mapDispatchToProps = {
  modalUserUnassignedOpen
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ButtonUserUnassigned);
