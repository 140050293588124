import { AUTH_REQUEST, AUTH_SUCCESS, AUTH_FAIL } from './types';

export function auth(payload) {
  return {
    type: AUTH_REQUEST,
    payload
  };
}

export function authSuccess(payload) {
  return {
    type: AUTH_SUCCESS,
    payload
  };
}

export function authFail(payload) {
  return {
    type: AUTH_FAIL,
    payload
  };
}
