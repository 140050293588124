import React, {
  useEffect,
  useCallback, useState
} from 'react';
import {
  Button, Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select, Typography
} from 'antd';

const { confirm } = Modal;

const FormEditUserOnListUser = ({
  listRoles,
  listData,
  record,
  editAdmin,
  deleteAdmin,
  setExpandedRowKeys
}) => {
  const [isBulkLoadChecked, setIsBulkLoadChecked] = useState(record?.isBulkLoadEnabled);
  const [form] = Form.useForm();

  useEffect(() => {
    if (record?.uuid) {
      form.setFieldsValue({
        lastName: record?.lastName,
        firstName: record?.firstName,
        middleName: record?.middleName,
        login: record?.login,
        email: record?.email,
        uuidOrganize: record?.organization?.uuid,
        code: record?.role?.code,
        isBulkLoadEnabled: record?.isBulkLoadEnabled
      });
    }
  }, [form, record]);

  const onClose = useCallback(() => {
    form.setFieldsValue({
      lastName: record?.lastName,
      firstName: record?.firstName,
      middleName: record?.middleName,
      login: record?.login,
      email: record?.email,
      uuidOrganize: record?.organization?.uuid,
      code: record?.role?.code,
      isBulkLoadEnabled: record?.isBulkLoadEnabled
    });
    setExpandedRowKeys([]);
  }, [setExpandedRowKeys, form, record]);

  /** Редактирование пользователя */
  const handleFormSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        editAdmin({
          data: {
            ...values,
            uuid: record?.uuid
          }
        });
      })
      .catch((errorInfo) => {

      });
  }, [editAdmin, form, record]);

  /** Удаление пользователя */
  const onDeleteAdmin = useCallback(() => {
    confirm({
      title: 'Удалить пользователя?',
      onOk() {
        deleteAdmin({
          uuid: record?.uuid
        });
      }
    });
  }, [record, deleteAdmin]);

  return (
    <Form name="basic"
          initialValues={{
            layout: 'vertical'
          }}
          layout="vertical"
          form={form}
          autoComplete="off"
          requiredMark={false}
    >
      <Row gutter={[40, 8]}>
        <Col md={24} lg={8}>
          <Form.Item label="Фамилия"
                     name="lastName"
                     rules={[
                       {
                         required: true,
                         message: 'Введите фамилию'
                       }
                     ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={24} lg={8}>
          <Form.Item label="Имя"
                     name="firstName"
                     rules={[
                       {
                         required: true,
                         message: 'Введите имя'
                       }
                     ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={24} lg={8}>
          <Form.Item label="Отчество"
                     name="middleName"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={24} lg={8}>
          <Form.Item label="Почта"
                     name="email"
                     rules={[
                       {
                         required: true,
                         message: 'Введите почту'
                       }
                     ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={24} lg={8}>
          <Form.Item label="Логин"
                     name="login"
                     rules={[
                       {
                         required: true,
                         message: 'Введите логин'
                       }
                     ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={24} lg={8}>
          <Form.Item label="Роль"
                     name="code"
                     rules={[
                       {
                         required: true,
                         message: 'Выберите роль'
                       }
                     ]}
          >
            <Select style={{ width: '100%' }}
                    placeholder="Выберите роль"
                    fieldNames={{ label: 'title', value: 'code' }}
                    options={listRoles}
            />
          </Form.Item>
        </Col>
        <Col md={24} lg={8}>
          <Form.Item label="Организация"
                     name="uuidOrganize"
          >
            <Select style={{ width: '100%' }}
                    placeholder="Выберите организацию"
                    fieldNames={{ label: 'title', value: 'uuid' }}
                    options={listData}
            />
          </Form.Item>
        </Col>
        <Col md={24} lg={8} style={{ paddingTop: 37 }}>
          <Form.Item name="isBulkLoadEnabled" label="" valuePropName="checked"
          >
            <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
              <Typography style={{ marginRight: 10 }}>Разрешена пакетная загрузка персонала</Typography>
              <Checkbox checked={isBulkLoadChecked}
                        onChange={(event) => setIsBulkLoadChecked(event.target.checked)} />
            </div>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[40, 8]}>
        <Col md={24} lg={8}>
          <Button type="dashed" block onClick={onDeleteAdmin}>
            Удалить
          </Button>
        </Col>
        <Col md={24} lg={8}>
          <Button block onClick={onClose}>
            Отмена
          </Button>
        </Col>
        <Col md={24} lg={8}>
          <Form.Item>
            <Button type="primary" htmlType="submit" block onClick={handleFormSubmit}>
              Сохранить изменения
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FormEditUserOnListUser;
