import * as actionTypes from './types';

const initialState = {
  listData: [],
  listDataReports: [],
  infoData: null,
  staticData: {},
  selectOrganization: null,
  pushData: null
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_LIST_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        listData: payload
      };
    case actionTypes.GET_INFO_ORGANIZATION_SUCCESS:
      return {
        ...state,
        infoData: payload
      };
    case actionTypes.GET_STATIC_ORGANIZATION_SUCCESS:
      return {
        ...state,
        staticData: payload
      };
    case actionTypes.SET_SELECT_ORGANIZATION:
      return {
        ...state,
        selectOrganization: payload
      };
    case actionTypes.GET_LIST_ORGANIZATION_REPORTS_SUCCESS:
      return {
        ...state,
        listDataReports: payload
      };
    case actionTypes.GET_PUSH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        pushData: payload
      };
    default:
      return state;
  }
};

export default reducer;
