import React, {
  useMemo, useEffect
} from 'react';
import { useHistory } from 'react-router';
import { Layout as LayoutAntd, Spin } from 'antd';
import Lottie from 'react-lottie';
import Header from '../../components/Header';
import style from './layout.module.scss';
import Menu from '../Menu';
import * as animationData from './loadingCube.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const Layout = ({
  children,
  userReducer,
  userCheck,
  loaderReducer
}) => {
  const history = useHistory();
  useEffect(() => {
    if (
      !userReducer.loading && history && history.location && history.location.pathname !== '/'
      && !(userReducer && userReducer.uuid)
    ) {
      userCheck();
    }
  }, [history, userReducer, userCheck]);

  const renderIndicator = useMemo(() => loaderReducer.visible && (
    <Lottie options={defaultOptions}
            height={300}
            width={300}
    />
  ), [loaderReducer.visible]);

  const renderMenu = useMemo(() => {
    if (userReducer && userReducer.uuid) {
      return (<Menu />);
    }
    return null;
  }, [userReducer]);

  const renderContainer = useMemo(() => (
    <div className={style.container}>
      { renderMenu }
      <div className={style.children}>{ children }</div>
    </div>
  ), [children, renderMenu]);

  return (
    <Spin spinning={!!loaderReducer.visible} indicator={renderIndicator}>
      <LayoutAntd className={style.layout}>
        <Header isUser={userReducer && userReducer.uuid} />
        { renderContainer }
      </LayoutAntd>
    </Spin>
  );
};

export default Layout;
