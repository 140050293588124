import {
  all, call, put, takeEvery
} from 'redux-saga/effects';
import moment from 'moment';
import * as actions from './actions';
import * as actionTypes from './types';
import { get, put as axiosPut } from '../../utils/api';
import { popupLoaderClose, popupLoaderOpen } from '../loader/actions';
import { INTERNAL_VOTE, INTERNAL_VOTE_STOP } from '../../utils/url';
import { errorModal, openNotificationWithIcon } from '../../utils/function';

function* getStaticInternalVote({ payload }) {
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      get,
      INTERNAL_VOTE(payload.organizationUuid)
    );
    yield put(actions.getStaticInternalVoteSuccess(response));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* setSelectOrganizationOnVote({ payload }) {
  try {
    yield put(popupLoaderOpen());

    yield put(actions.getStaticInternalVote({
      organizationUuid: payload
    }));

    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* setStartOrUpdateInternalVote({ payload }) {
  try {
    yield put(popupLoaderOpen());

    let beginAt = moment(payload.beginAt);
    beginAt.hour(0);
    beginAt.minute(0);
    beginAt.second(0);
    beginAt.millisecond(0);
    beginAt = beginAt.valueOf();

    let endAt = null;
    if (payload.endAt) {
      endAt = moment(payload.endAt);
      endAt.hour(23);
      endAt.minute(59);
      endAt.second(59);
      endAt.millisecond(999);
      endAt = endAt.valueOf();
    }

    yield call(
      axiosPut,
      INTERNAL_VOTE(payload.organizationUuid),
      {
        countLike: payload.countLike,
        beginAt,
        endAt,
        reasons: payload.reasons
      }
    );
    yield put(actions.getStaticInternalVote({
      organizationUuid: payload.organizationUuid
    }));
    yield put(popupLoaderClose());
    openNotificationWithIcon(
      'success',
      !payload.update ? 'Голосование началось' : 'Данные обновлены',
      null
    );
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* setStopInternalVote({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      axiosPut,
      INTERNAL_VOTE_STOP(payload.organizationUuid)
    );
    yield put(actions.getStaticInternalVote({
      organizationUuid: payload.organizationUuid
    }));
    yield put(popupLoaderClose());
    openNotificationWithIcon(
      'success',
      'Голосование остановлено',
      null
    );
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

// eslint-disable-next-line
export default function* () {
  yield all([
    takeEvery(actionTypes.GET_STATIC_INTERNAL_VOTE, getStaticInternalVote),
    takeEvery(actionTypes.SET_SELECT_ORGANIZATION_ON_VOTE, setSelectOrganizationOnVote),
    takeEvery(actionTypes.SET_START_OR_UPDATE_INTERNAL_VOTE, setStartOrUpdateInternalVote),
    takeEvery(actionTypes.SET_STOP_INTERNAL_VOTE, setStopInternalVote)
  ]);
}
