export const SET_SELECT_ORGANIZATION_ON_PERSONNEL = 'SET_SELECT_ORGANIZATION_ON_PERSONNEL';

export const GET_LIST_PERSONNEL_ON_ORGANIZATION = 'GET_LIST_PERSONNEL_ON_ORGANIZATION';
export const GET_LIST_PERSONNEL_ON_ORGANIZATION_SUCCESS = 'GET_LIST_PERSONNEL_ON_ORGANIZATION_SUCCESS';

export const GET_LIST_PERSONNEL_ON_ORGANIZATION_DOP = 'GET_LIST_PERSONNEL_ON_ORGANIZATION_DOP';
export const GET_LIST_PERSONNEL_ON_ORGANIZATION_DOP_SUCCESS = 'GET_LIST_PERSONNEL_ON_ORGANIZATION_DOP_SUCCESS';

export const CREATE_PERSONNEL_ON_ORGANIZATION = 'CREATE_PERSONNEL_ON_ORGANIZATION';

export const EDIT_PERSONNEL_ON_ORGANIZATION = 'EDIT_PERSONNEL_ON_ORGANIZATION';

export const DELETE_PERSONNEL_ON_ORGANIZATION = 'DELETE_PERSONNEL_ON_ORGANIZATION';

export const OPEN_MODAL_PERSONNEL_ON_ORGANIZATION = 'OPEN_MODAL_PERSONNEL_ON_ORGANIZATION';
export const CLOSE_MODAL_PERSONNEL_ON_ORGANIZATION = 'CLOSE_MODAL_PERSONNEL_ON_ORGANIZATION';

export const OPEN_MODAL_CREATE_PERSON = 'OPEN_MODAL_CREATE_PERSON';
export const CLOSE_MODAL_CREATE_PERSON = 'CLOSE_MODAL_CREATE_PERSON';

export const IMPORT_PERSON_UPLOAD_CSV_FILE = 'IMPORT_PERSON_UPLOAD_CSV_FILE';
export const ACCEPT_ALL_PERSONS_IN_ORGANIZATION = 'ACCEPT_ALL_PERSONS_IN_ORGANIZATION';
