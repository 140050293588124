import * as actionsTypes from './types';

export function getListAdmins(payload) {
  return {
    type: actionsTypes.GET_LIST_ADMINS,
    payload
  };
}
export function getListAdminsSuccess(payload) {
  return {
    type: actionsTypes.GET_LIST_ADMINS_SUCCESS,
    payload
  };
}

export function getListRoles(payload) {
  return {
    type: actionsTypes.GET_LIST_ROLES,
    payload
  };
}
export function getListRolesSuccess(payload) {
  return {
    type: actionsTypes.GET_LIST_ROLES_SUCCESS,
    payload
  };
}

export function createAdmin(payload) {
  return {
    type: actionsTypes.CREATE_ADMIN,
    payload
  };
}

export function editAdmin(payload) {
  return {
    type: actionsTypes.EDIT_ADMIN,
    payload
  };
}

export function deleteAdmin(payload) {
  return {
    type: actionsTypes.DELETE_ADMIN,
    payload
  };
}

export function resetPassword(payload) {
  return {
    type: actionsTypes.RESET_PASSWORD,
    payload
  };
}
