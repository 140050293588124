import { connect } from 'react-redux';
import NotificationManagement from './NotificationManagement';
import { editPushOrganization, getPushOrganization } from '../../store/organization/actions';
import { editPushEvent, getPushEvent } from '../../store/event/actions';

const mapStateToProps = ({
  organizationReducer,
  eventReducer
}) => ({
  selectOrganization: organizationReducer.selectOrganization,
  pushData: organizationReducer.pushData,
  selectEvent: eventReducer.selectEvent,
  pushDataEvent: eventReducer.pushData
});
const mapDispatchToProps = {
  getPushOrganization,
  editPushOrganization,
  getPushEvent,
  editPushEvent
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationManagement);
