import {
  all, call, put, takeEvery
} from 'redux-saga/effects';
import * as actions from './actions';
import * as actionTypes from './types';
import { get } from '../../utils/api';
import { CHECK } from '../../utils/url';
import { popupLoaderClose, popupLoaderOpen } from '../loader/actions';

function* userCheck({ payload }) {
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      get,
      CHECK
    );
    yield put(actions.userCheckSuccess(response));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(actions.userCheckFail());

    localStorage.removeItem('Authorization');
    document.location.href = '/';
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

// eslint-disable-next-line
export default function* () {
  yield all([
    takeEvery(actionTypes.USER_CHECK, userCheck)
  ]);
}
