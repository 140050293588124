import axios from 'axios';
import { getMocksByURL, mocksURLs } from './mocks';

const getBaseUrl = () => `${window.location.origin}`;

export const BASE_URL = getBaseUrl();
export const API_BASE = `${BASE_URL}/`;

const defaultConfig = {
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'X-Timezone-Offset': String(new Date().getTimezoneOffset())
  }
};

export const http = axios.create({
  baseURL: API_BASE,
  validateStatus: (status) => status >= 200 && status < 300
});

http.interceptors.request.use((request) => ({
  ...request,
  headers: {
    ...request.headers,
    Authorization: `Bearer ${localStorage.getItem('Authorization')}`
  }
}));

const httpFile = axios.create({
  baseURL: API_BASE,
  responseType: 'blob'
});

httpFile.interceptors.request.use((request) => ({
  ...request,
  headers: {
    ...request.headers,
    Authorization: `Bearer ${localStorage.getItem('Authorization')}`
  }
}));

export const get = async (url, config = defaultConfig) => {
  try {
    const mock = mocksURLs.find((el) => url.includes(el.url));
    if (mock) {
      return getMocksByURL(mock, url);
    }
    const answer = await http.get(url, config);
    const result = answer.data;
    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const post = async (url, payload, config = defaultConfig) => {
  try {
    const mock = mocksURLs.find((el) => url.includes(el.url));
    if (mock) {
      return getMocksByURL(mock, url);
    }
    const answer = await http.post(url, payload, config);
    const result = answer.data;
    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const put = async (url, payload, config = defaultConfig) => {
  try {
    const mock = mocksURLs.find((el) => url.includes(el.url));
    if (mock) {
      return getMocksByURL(mock, url);
    }
    const answer = await http.put(url, payload, config);
    const result = answer.data;
    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const apiDelete = async (url, payload, config = defaultConfig) => {
  try {
    const answer = await http.delete(url, { ...config, data: payload });
    const result = answer.data;
    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFile = async (url, config = defaultConfig) => {
  try {
    const answer = await httpFile.get(url, config);
    const fileName = answer?.headers['content-disposition']?.split('filename=')[1] || 'report.xlsx';
    const fileBlob = answer.data;
    return Promise.resolve([fileName, fileBlob]);
  } catch (error) {
    return Promise.reject(error);
  }
};
