import React, { useCallback, useEffect } from 'react';
import {
  Button,
  Row,
  Space,
  Select,
  Col,
  Upload
} from 'antd';
import style from './personnelmanagement.module.scss';
import TablePerson from '../../components/TablePerson';
import ModalListPersonOnOrganization
  from '../../components/ModalListPersonOnOrganization';
import ModalFormCreatingOrEditPerson
  from '../../components/ModalFormCreatingOrEditPerson';

const PersonnelManagement = ({
  listData,
  selectOrganization,
  getListOrganizations,
  setSelectOrganizationOnPersonnel,
  openModalPersonnelOnOrganization,
  openModalCreatePerson,
  acceptAllPersonsInOrganization,
  role, isBulkLoadEnabled,
  importPersonUploadCsvFile
}) => {
  useEffect(() => {
    getListOrganizations();
  }, [getListOrganizations]);

  const onChangeOrganize = useCallback((value) => {
    setSelectOrganizationOnPersonnel(value);
  }, [setSelectOrganizationOnPersonnel]);

  const beforeUpload = useCallback((file) => {
    importPersonUploadCsvFile({
      organizationUUID: selectOrganization,
      file
    });
  }, [importPersonUploadCsvFile, selectOrganization]);

  const onAcceptAllPersonsInOrganization = useCallback((value) => {
    acceptAllPersonsInOrganization({ organizationUuid: selectOrganization });
  }, [acceptAllPersonsInOrganization, selectOrganization]);

  return (
    <div className="dd-container">
      <div className={`dd-container-main ${style.containers}`}>
        <div className={style.header}>
          <h2>Управление персоналом</h2>
          <div className={style.imports}>
            Шаблоны файлов для импорта сотрудников:
            <a className={style.download} download href="/__static/import/template.xlsx">template.xlsx</a>
            <a className={style.download} download href="/__static/import/template.csv">template.csv</a>
          </div>
          <Row gutter={[20, 8]}>
            <Col flex="auto" style={{ maxWidth: 500 }}>
              <Select style={{ width: '100%' }}
                      placeholder="Выберите организацию"
                      fieldNames={{ label: 'title', value: 'uuid' }}
                      options={listData}
                      onChange={onChangeOrganize}
                      value={selectOrganization}
              />
            </Col>
            <Col flex="300px">
              <Space size={20}>
                <Upload accept=".xlsx, .csv"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                >
                  <Button type="primary"
                          disabled={!isBulkLoadEnabled || !selectOrganization}
                  >
                    Загрузить файл
                  </Button>
                </Upload>
                <Button onClick={openModalCreatePerson}
                        disabled={!selectOrganization}
                >
                  Добавить пользователя
                </Button>
                <Button onClick={onAcceptAllPersonsInOrganization}
                        disabled={!selectOrganization}
                >
                  Акцептовать всех пользователей
                </Button>
                <ModalFormCreatingOrEditPerson />
              </Space>
            </Col>
          </Row>
        </div>
        <div className={style.main}>
          { !selectOrganization && <h2>Информация о персонале</h2> }
          { selectOrganization && <TablePerson key="table_person_3" /> }
        </div>
        <div className={style.footer}>
          { selectOrganization && (
            <>
              <Button onClick={openModalPersonnelOnOrganization}>
                Развернуть / Редактировать таблицу
              </Button>
              <ModalListPersonOnOrganization key="ModalListPersonOnOrganization_1" />
            </>
          ) }

        </div>
      </div>
    </div>
  );
};

export default PersonnelManagement;
