import React, {
  useCallback,
  useEffect
} from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select
} from 'antd';
import { format } from 'date-fns';
import style from './reportupload.module.scss';

const { RangePicker } = DatePicker;

const ReportUploadAndQuickStatistics = ({
  selectOrganization,
  staticData,
  getStaticOrganization,
  listDataReports,
  getListOrganizationReports,
  getCreateOrganizationReport
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectOrganization) {
      getStaticOrganization(selectOrganization);
      getListOrganizationReports(selectOrganization);
    }
  }, [
    getListOrganizationReports,
    getStaticOrganization,
    selectOrganization
  ]);

  const handleFormSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        getCreateOrganizationReport({
          uuid: selectOrganization,
          code: values.code,
          beginAt: values.date[0],
          endAt: values.date[1]
        });
      })
      .catch((errorInfo) => {

      });
  }, [
    form,
    getCreateOrganizationReport,
    selectOrganization
  ]);

  return (
    <>
      <h2>Выгрузка отчета</h2>
      <Form name="basic"
            form={form}
            layout="vertical"
            autoComplete="off"
            requiredMark={false}
      >
        <Row gutter={[40, 8]}>
          <Col md={24} lg={12}>
            <Form.Item label="Название отчета"
                       name="code"
                       rules={[
                         {
                           required: true,
                           message: 'Выберите отчет из списка'
                         }
                       ]}
            >
              <Select style={{ width: '100%' }}
                      placeholder="Выберите отчет"
                      fieldNames={{
                        label: 'title',
                        value: 'code'
                      }}
                      options={listDataReports}
              />
            </Form.Item>
          </Col>
          <Col md={24} lg={12}>
            <Form.Item label="Даты отчета"
                       name="date"
                       rules={[
                         {
                           required: true,
                           message: 'Выберите даты'
                         }
                       ]}
            >
              <RangePicker format="DD.MM.YYYY" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item>
            <Button type="primary"
                    htmlType="submit"
                    block
                    onClick={handleFormSubmit}
                    disabled={!selectOrganization}
            >
              Выгрузить отчет
            </Button>
          </Form.Item>
        </Row>
      </Form>
      <h2>Быстрая статистика</h2>
      <Row className={style.rowStatic}>

        <div className="dd-organization-static">
          <div className="dd-organization-static-img">
            <img src="/images/calendar.svg" alt="Календаль" />
          </div>
          <div className="dd-organization-static-text">
            <span>Дата голосования:</span>
          </div>
          <div className="dd-organization-static-static">
            <span>{staticData.beginAtVote && format(new Date(staticData.beginAtVote), 'dd.MM.yyyy HH:mm')}</span>
          </div>
        </div>

        <div className="dd-organization-static">
          <div className="dd-organization-static-img">
            <img src="/images/calendar.svg" alt="Календаль" />
          </div>
          <div className="dd-organization-static-text">
            <span>Дата завершения:</span>
          </div>
          <div className="dd-organization-static-static">
            <span>{staticData.endAtVote && format(new Date(staticData.endAtVote), 'dd.MM.yyyy HH:mm')}</span>
          </div>
        </div>

        <div className="dd-organization-static">
          <div className="dd-organization-static-img">
            <img src="/images/calendar.svg" alt="Календаль" />
          </div>
          <div className="dd-organization-static-text">
            <span>Количество участников:</span>
          </div>
          <div className="dd-organization-static-static">
            <span>{staticData.countVoters}</span>
          </div>
        </div>

        <div className="dd-organization-static">
          <div className="dd-organization-static-img">
            <img src="/images/calendar.svg" alt="Календаль" />
          </div>
          <div className="dd-organization-static-text">
            <span>Начали голосовать:</span>
          </div>
          <div className="dd-organization-static-static">
            <span>{staticData.startedVoting}</span>
          </div>
        </div>

        <div className="dd-organization-static">
          <div className="dd-organization-static-img">
            <img src="/images/calendar.svg" alt="Календаль" />
          </div>
          <div className="dd-organization-static-text">
            <span>Закончили голосовать:</span>
          </div>
          <div className="dd-organization-static-static">
            <span>{staticData.finishedVoting}</span>
          </div>
        </div>

      </Row>
    </>
  );
};

export default ReportUploadAndQuickStatistics;
