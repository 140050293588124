import { connect } from 'react-redux';
import FormEditUserOnListUser from './FormEditUserOnListUser';
import { editAdmin, deleteAdmin } from '../../store/roles/actions';

const mapStateToProps = ({
  rolesReducer,
  organizationReducer
}) => ({
  listRoles: rolesReducer.listRoles,
  listData: organizationReducer.listData
});
const mapDispatchToProps = {
  editAdmin,
  deleteAdmin
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormEditUserOnListUser);
