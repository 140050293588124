import React, {
  useCallback,
  useState,
  useEffect,
  useMemo
} from 'react';
import {
  Modal,
  Table,
  Space,
  Input
} from 'antd';

const columns = [
  {
    title: 'Название организации',
    dataIndex: 'title'
  },
  {
    title: 'Почта',
    dataIndex: 'email'
  },
  {
    title: 'ИНН',
    dataIndex: 'inn'
  }
];

const dataFilter = {
  search: null,
  offset: 0,
  limit: 10
};

const ModalUserUnassigned = ({
  isModalVisible,
  userUuid,
  data,
  pagination,
  modalUserUnassignedClose,
  getListUnassignedUsersOrganizations,
  operationUnassignedUserOnOrganization
}) => {
  const [search, setSearch] = useState();
  const [organizationUuid, setOrganizationUuid] = useState();
  useEffect(() => {
    getListUnassignedUsersOrganizations(dataFilter);
  }, [getListUnassignedUsersOrganizations]);

  const handleTableChange = useCallback((pagin, filters, sorter) => {
    getListUnassignedUsersOrganizations({
      search: search && search.length >= 3,
      offset: (pagin.current - 1) * pagin.pageSize,
      limit: pagin.pageSize
    });
  }, [getListUnassignedUsersOrganizations, search]);
  const onChangeSearch = useCallback((e) => {
    setSearch(e.target.value);
    if (e.target.value.length >= 3) {
      getListUnassignedUsersOrganizations({
        search: e.target.value,
        offset: 0,
        limit: pagination.pageSize,
        isSearch: true
      });
    } else {
      getListUnassignedUsersOrganizations({
        search: null,
        offset: 0,
        limit: pagination.pageSize,
        isSearch: true
      });
    }
  }, [getListUnassignedUsersOrganizations, pagination.pageSize]);

  const handleOk = useCallback(() => {
    operationUnassignedUserOnOrganization({
      userUuid,
      organizationUuid
    });
  }, [operationUnassignedUserOnOrganization, organizationUuid, userUuid]);
  const handleCancel = useCallback(() => {
    modalUserUnassignedClose();
  }, [modalUserUnassignedClose]);

  const onChangeTable = useCallback((selectedRowKeys, selectedRows) => {
    setOrganizationUuid(selectedRows[0].uuid);
  }, []);

  const renderSearch = useMemo(() => (
    <Space style={{ marginBottom: 16 }}>
      <Input placeholder="Поиск"
             prefix={<img src="/images/search.svg" alt="Поиск" />}
             value={search}
             onChange={onChangeSearch}
      />
    </Space>
  ), [search, onChangeSearch]);
  const renderTable = useMemo(() => (
    <Table columns={columns}
           rowKey={(record) => record.uuid}
           dataSource={data}
           pagination={pagination}
           onChange={handleTableChange}
           rowSelection={{
             type: 'radio',
             onChange: onChangeTable
           }}
    />
  ), [
    data,
    handleTableChange,
    pagination,
    onChangeTable
  ]);

  return (
    <Modal title="Выбрать организацию"
           visible={isModalVisible}
           onOk={handleOk}
           onCancel={handleCancel}
    >
      <div className="">
        <div className="">
          { renderSearch }
          { renderTable }
        </div>
      </div>
    </Modal>
  );
};

export default ModalUserUnassigned;
