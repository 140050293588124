import { connect } from 'react-redux';
import Menu from './Menu';

const mapStateToProps = ({
  userReducer: {
    role
  }
}) => ({
  role
});

export default connect(
  mapStateToProps,
  null
)(Menu);
