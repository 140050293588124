import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import createReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

function createSagaInjector(runSaga) {
  const injectedSagas = new Map();
  const isInjected = (key) => injectedSagas.has(key);
  const injectSaga = (key, saga) => {
    if (isInjected(key)) return;
    const task = runSaga(saga);
    injectedSagas.set(key, task);
  };

  injectSaga('root', rootSaga);

  return injectSaga;
}

const store = createStore(
  createReducer(),
  compose(
    composeWithDevTools(
      applyMiddleware(sagaMiddleware)
    )
  )
);
store.injectSaga = createSagaInjector(sagaMiddleware.run, rootSaga);

store.asyncReducers = {};

store.injectReducer = (key, asyncReducer) => {
  store.asyncReducers[key] = asyncReducer;
  store.replaceReducer(createReducer(store.asyncReducers));
};
export default store;
