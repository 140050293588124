import {
  all,
  call,
  put,
  takeLatest,
  delay,
  select
} from 'redux-saga/effects';
import * as actions from './actions';
import * as actionTypes from './types';
import { get, put as axiosPut, apiDelete } from '../../utils/api';
import { popupLoaderClose, popupLoaderOpen } from '../loader/actions';
import { errorModal, openNotificationWithIcon } from '../../utils/function';
import {
  UNASSIGNED_USERS,
  UNASSIGNED_USER_ORGANIZATIONS,
  UNASSIGNED_USER_ON_ORGANIZATION,
  UNASSIGNED_USER_DELETE
} from '../../utils/url';

/**
 *     {
 *       "uuid": "31cd402e-bdaf-47a1-b129-000000000115",
 *       "lastName": "Вещающий",
 *       "firstName": "Оратор",
 *       "middleName": null,
 *       "personalPhone": "79998887700",
 *       "personalEmail": "test@test.ru"
 *     }
 * */
function* getListUnassignedUsers({ payload }) {
  if (payload.isSearch) {
    yield put(popupLoaderClose());
    yield delay(1000);
  }
  try {
    yield put(popupLoaderOpen());
    let response = yield call(
      get,
      // eslint-disable-next-line max-len
      `${UNASSIGNED_USERS}?offset=${payload.offset}&limit=${payload.limit}${payload.search ? `&search=${encodeURI(payload.search)}` : ''}`
    );
    let offset = payload?.offset ? payload?.offset : 0;
    if (response?.rows && response?.rows.length === 0 && payload.offset > 0) {
      offset -= payload.limit;
      response = yield call(
        get,
        // eslint-disable-next-line max-len
        `${UNASSIGNED_USERS}?offset=${offset}&limit=${payload.limit}${payload.search ? `&search=${encodeURI(payload.search)}` : ''}`
      );
    }
    yield put(actions.getListUnassignedUsersSuccess({
      ...response,
      limit: payload.limit,
      search: payload.search,
      current: Math.round(offset / payload.limit) + 1
    }));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* getListUnassignedUsersOrganizations({ payload }) {
  if (payload.isSearch) {
    yield put(popupLoaderClose());
    yield delay(1000);
  }
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      get,
      // eslint-disable-next-line max-len
      `${UNASSIGNED_USER_ORGANIZATIONS}?offset=${payload.offset}&limit=${payload.limit}${payload.search ? `&search=${encodeURI(payload.search)}` : ''}`
    );
    yield put(actions.getListUnassignedUsersOrganizationsSuccess({
      ...response,
      limit: payload.limit
    }));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

function* updateListUnassignedUsers({ payload }) {
  const { users } = yield select(({ unassignedUsersReducer }) => unassignedUsersReducer);
  yield put(
    actions.getListUnassignedUsers({
      search: users.pagination.search && users.pagination.search.length >= 3,
      offset: ((users.pagination.current ? users.pagination.current : 1) - 1) * users.pagination.pageSize,
      limit: users.pagination.pageSize
    })
  );
}

function* deleteUnassignedUser({ payload }) {
  try {
    yield call(
      apiDelete,
      UNASSIGNED_USER_DELETE(payload.userUuid)
    );
    openNotificationWithIcon(
      'success',
      'Пользователь удален!',
      null
    );
    yield all([
      updateListUnassignedUsers({})
    ]);
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

// выбираем пользователя в организацию
function* operationUnassignedUserOnOrganization({ payload }) {
  try {
    yield put(popupLoaderOpen());
    yield call(
      axiosPut,
      UNASSIGNED_USER_ON_ORGANIZATION(payload.userUuid, payload.organizationUuid)
    );
    yield put(popupLoaderClose());
    yield put(actions.modalUserUnassignedClose());
    openNotificationWithIcon(
      'success',
      'Пользователь добавлен в организацию',
      null
    );
    yield all([
      updateListUnassignedUsers({})
    ]);
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

// eslint-disable-next-line
export default function* () {
  yield all([
    takeLatest(actionTypes.GET_LIST_UNASSIGNED_USERS, getListUnassignedUsers),
    takeLatest(actionTypes.GET_LIST_UNASSIGNED_USERS_ORGANIZATIONS, getListUnassignedUsersOrganizations),
    takeLatest(actionTypes.OPERATION_UNASSIGNED_USER_ON_ORGANIZATION, operationUnassignedUserOnOrganization),
    takeLatest(actionTypes.DELETE_UNASSIGNED_USER, deleteUnassignedUser)
  ]);
}
