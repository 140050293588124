import * as actionsTypes from './types';

export function userCheck(payload) {
  return {
    type: actionsTypes.USER_CHECK,
    payload
  };
}

export function userCheckSuccess(payload) {
  return {
    type: actionsTypes.USER_CHECK_SUCCESS,
    payload
  };
}

export function userCheckFail(payload) {
  return {
    type: actionsTypes.USER_CHECK_FAIL,
    payload
  };
}
