import React from 'react';
import { Route } from 'react-router-dom';
import { listMenu } from '../../utils/config';

const RenderRoute = ({
  role
}) => (
  role && listMenu[role.code].map((item) => (
    <Route key={item.link} path={`/${item.link}`} component={item.component} />
  ))
);

export default React.memo(RenderRoute);
