import {
  all, call, put, takeEvery
} from 'redux-saga/effects';
import * as actionTypes from './types';
import { post } from '../../utils/api';
import { LOGIN } from '../../utils/url';
import { userCheckSuccess } from '../user/actions';
import { popupLoaderOpen, popupLoaderClose } from '../loader/actions';
import { errorModal } from '../../utils/function';

function* auth({ payload }) {
  try {
    yield put(popupLoaderOpen());
    const response = yield call(
      post,
      LOGIN,
      {
        login: payload.login,
        password: payload.password
      }
    );
    if (response.jwtAccess) {
      localStorage.setItem('Authorization', response.jwtAccess);
    }
    yield put(userCheckSuccess(response.admin));
    yield put(popupLoaderClose());
  } catch (e) {
    yield put(popupLoaderClose());
    errorModal(
      e?.response?.data?.error,
      e?.response?.data?.message,
      e?.response?.data?.data
    );
  }
}

// eslint-disable-next-line
export default function* () {
  yield all([
    takeEvery(actionTypes.AUTH_REQUEST, auth)
  ]);
}
