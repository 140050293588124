import React, {
  useCallback,
  useEffect
} from 'react';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Row,
  Select
} from 'antd';
import { format } from 'date-fns';
import style from './event.reportupload.module.scss';

const { RangePicker } = DatePicker;

const EventReportUpload = ({
  selectEvent,
  listDataReports,
  getListEventReports,
  getCreateEventReport
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectEvent) {
      getListEventReports(selectEvent);
    }
  }, [
    getListEventReports,
    selectEvent
  ]);

  const handleFormSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        getCreateEventReport({
          uuid: selectEvent,
          code: values.code,
          beginAt: values.date[0],
          endAt: values.date[1]
        });
      })
      .catch((errorInfo) => {

      });
  }, [
    form,
    getCreateEventReport,
    selectEvent
  ]);

  return (
    <>
      <h2>Выгрузка отчета</h2>
      <Form name="basic"
            form={form}
            layout="vertical"
            autoComplete="off"
            requiredMark={false}
      >
        <Row gutter={[40, 8]}>
          <Col md={24} lg={12}>
            <Form.Item label="Название отчета"
                       name="code"
                       rules={[
                         {
                           required: true,
                           message: 'Выберите отчет из списка'
                         }
                       ]}
            >
              <Select style={{ width: '100%' }}
                      placeholder="Выберите отчет"
                      fieldNames={{
                        label: 'title',
                        value: 'code'
                      }}
                      options={listDataReports}
              />
            </Form.Item>
          </Col>
          <Col md={24} lg={12}>
            <Form.Item label="Даты отчета"
                       name="date"
                       rules={[
                         {
                           required: true,
                           message: 'Выберите даты'
                         }
                       ]}
            >
              <RangePicker format="DD.MM.YYYY" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item>
            <Button type="primary"
                    htmlType="submit"
                    block
                    onClick={handleFormSubmit}
                    disabled={!selectEvent}
            >
              Выгрузить отчет
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </>
  );
};

export default EventReportUpload;
