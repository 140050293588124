import React, { useCallback, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Button, Typography, Checkbox
} from 'antd';

const FormCreateUser = ({
  listRoles,
  listData,
  createAdmin
}) => {
  const [roleCode, setRoleCode] = useState('ADMIN');
  const [isBulkLoadEnabled, setIsBulkLoadEnabled] = useState(true);
  const [form] = Form.useForm();

  const handleFormSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        createAdmin({
          data: values,
          form
        });
      })
      .catch((errorInfo) => {

      });
  }, [createAdmin, form]);

  return (
    <Form name="basic"
          initialValues={{
            layout: 'vertical'
          }}
          layout="vertical"
          form={form}
          autoComplete="off"
          requiredMark={false}
    >
      <Row gutter={[40, 8]}>
        <Col md={24} lg={8}>
          <Form.Item label="Фамилия"
                     name="lastName"
                     rules={[
                       {
                         required: true,
                         message: 'Введите фамилию'
                       }
                     ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={24} lg={8}>
          <Form.Item label="Имя"
                     name="firstName"
                     rules={[
                       {
                         required: true,
                         message: 'Введите имя'
                       }
                     ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={24} lg={8}>
          <Form.Item label="Отчество"
                     name="middleName"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={24} lg={8}>
          <Form.Item label="Почта"
                     name="email"
                     rules={[
                       {
                         required: true,
                         message: 'Введите почту'
                       }
                     ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={24} lg={8}>
          <Form.Item label="Логин"
                     name="login"
                     rules={[
                       {
                         required: true,
                         message: 'Введите логин'
                       }
                     ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={24} lg={8}>
          <Form.Item label="Роль"
                     name="code"
                     rules={[
                       {
                         required: true,
                         message: 'Выберите роль'
                       }
                     ]}
          >
            <Select style={{ width: '100%' }}
                    placeholder="Выберите роль"
                    fieldNames={{ label: 'title', value: 'code' }}
                    options={listRoles}
                    onSelect={(value) => setRoleCode(value)}
                    value={roleCode}
                    defaultValue={roleCode}
            />
          </Form.Item>
        </Col>
        <Col md={24} lg={12}>
          <Form.Item label="Организация"
                     name="uuid"
          >
            <Select style={{ width: '100%' }}
                    placeholder="Выберите организацию"
                    fieldNames={{ label: 'title', value: 'uuid' }}
                    options={listData}
            />
          </Form.Item>
        </Col>
        <Col md={24} lg={12} style={{ paddingTop: 7 }}>
          <Form.Item name="isBulkLoadEnabled" label=" " valuePropName="checked">
            <div style={{ display: 'flex', whiteSpace: 'nowrap' }} hidden={roleCode === 'OWNER'}>
              <Typography style={{ marginRight: 10 }}>Разрешена пакетная загрузка персонала</Typography>
              <Checkbox checked={isBulkLoadEnabled}
                        onChange={(event) => setIsBulkLoadEnabled(event.target.checked)} />
            </div>
          </Form.Item>
        </Col>
        <Col md={24} lg={12} style={{ paddingTop: 30 }}>
          <Form.Item>
            <Button type="primary"
                    block
                    htmlType="submit"
                    onClick={handleFormSubmit}
            >
              Создать пользователя
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FormCreateUser;
