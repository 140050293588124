import React, { useCallback } from 'react';
import {
  Modal
} from 'antd';
import style from './ModalFormCreatingOrEditPerson.module.scss';
import FormCreatingOrEditPerson from '../FormCreatingOrEditPerson';

const ModalFormCreatingOrEditPerson = ({
  isModalVisibleCreatePerson,
  closeModalCreatePerson
}) => {
  const handleCancel = useCallback(() => {
    closeModalCreatePerson();
  }, [closeModalCreatePerson]);

  return (
    <Modal visible={isModalVisibleCreatePerson}
           title="Добавить пользователя"
           onCancel={handleCancel}
           wrapClassName={style.modal}
           footer={null}
    >
      <FormCreatingOrEditPerson isModal />
    </Modal>
  );
};

export default ModalFormCreatingOrEditPerson;
