import { connect } from 'react-redux';
import ModalListPersonOnOrganization from './ModalListPersonOnOrganization';
import { closeModalPersonnelOnOrganization } from '../../store/personnel/actions';

const mapStateToProps = ({
  personnelReducer
}) => ({
  isModalVisible: personnelReducer.isModalVisible
});
const mapDispatchToProps = {
  closeModalPersonnelOnOrganization
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalListPersonOnOrganization);
