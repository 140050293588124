import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
  useLayoutEffect
} from 'react';
import {
  Input,
  Space,
  Table,
  Button
} from 'antd';
import { format } from 'date-fns';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import style from './tableperson.module.scss';
import FormCreatingOrEditPerson from '../FormCreatingOrEditPerson';

import {
  renderFIO,
  renderDepartment,
  renderStatus
} from '../../utils/function';

const columnsOnForm = [
  {
    width: 170,
    title: 'ФИО',
    dataIndex: '',
    render: renderFIO
  },
  {
    width: 170,
    title: 'Отдел',
    dataIndex: '',
    render: renderDepartment
  },
  {
    width: 190,
    title: 'Email',
    dataIndex: 'personalEmail'
  },
  {
    width: 170,
    title: 'Тел.моб.',
    dataIndex: 'personalPhone'
  },
  {
    width: 170,
    title: 'Статус',
    dataIndex: '',
    render: renderStatus
  }
];
const columnsOnModal = [
  {
    title: 'ФИО',
    dataIndex: '',
    render: renderFIO
  },
  {
    title: 'Табельный',
    dataIndex: '',
    render: (text, record, index) => record?.personalId
  },
  {
    title: 'Отдел 1',
    dataIndex: '',
    render: (text, record, index) => record?.employee?.department
  },
  {
    title: 'Отдел 2',
    dataIndex: '',
    render: (text, record, index) => record?.employee?.departmentTwo
  },
  {
    title: 'Отдел 3',
    dataIndex: '',
    render: (text, record, index) => record?.employee?.departmentThree
  },
  {
    title: 'Дата зачисления',
    dataIndex: '',
    // eslint-disable-next-line max-len
    render: (text, record, index) => record?.employee?.employmentDate && format(new Date(record?.employee?.employmentDate), 'dd.MM.yyyy HH:mm')
  },
  {
    title: 'Дата увольнения',
    dataIndex: '',
    // eslint-disable-next-line max-len
    render: (text, record, index) => record?.employee?.dismissalDate && format(new Date(record?.employee?.dismissalDate), 'dd.MM.yyyy HH:mm')
  },
  {
    title: 'Должность',
    dataIndex: '',
    render: (text, record, index) => record?.employee?.speciality
  }
];
const columnsOnModalDop = [
  {
    title: 'ФИО',
    dataIndex: '',
    render: renderFIO
  },
  {
    title: 'Эл.почта',
    dataIndex: 'personalEmail'
  },
  {
    title: (
      <span>
        Тел.рабочий
        <br />
        <small>Тел.мобильный</small>
      </span>
    ),
    dataIndex: '',
    render: (text, record, index) => (
      <span>
        { record?.employee?.workPhone }
        <br />
        { record?.personalPhone }
      </span>
    )
  },
  {
    title: (
      <span>
        Дата рождения
        <br />
        <small>Возраст / Пол</small>
      </span>
    ),
    dataIndex: '',
    render: (text, record, index) => (
      <span>
        { record?.birthDate && moment(record?.birthDate).format('DD.MM.YYYY') }
        <br />
        { `${
          (record?.birthDate && moment().diff(moment(record?.birthDate), 'years')) || ''
        } / ${
          // eslint-disable-next-line no-nested-ternary
          (record?.gender === 'Female' ? 'Ж' : (record?.gender === 'Male' ? 'M' : '')) || ''
        }` }
      </span>
    )
  },
  {
    title: 'Спец-ть',
    dataIndex: '',
    render: (text, record, index) => record?.employee?.speciality
  },
  {
    title: 'Достижения',
    dataIndex: '',
    render: (text, record, index) => record?.employee?.achievements
  },
  {
    title: 'Заслуги',
    dataIndex: '',
    render: (text, record, index) => record?.employee?.merits
  },
  {
    title: 'Статус',
    dataIndex: '',
    render: (text, record, index) => record?.employee?.status
  }
];

const dataFilter = {
  search: null,
  offset: 0,
  limit: 10
};

const dataFilterDop = {
  search: null,
  offset: 0,
  limit: 10
};

const TablePerson = ({
  data,
  pagination,
  dataDop,
  paginationDop,
  organizationUuid,
  getListPersonnelOnOrganization,
  getListPersonnelOnOrganizationDop,
  isModal,
  isModalVisible
}) => {
  const [keyRowExpanded, setKeyRowExpanded] = useState([]);
  const [search, setSearch] = useState();
  const [searchDop, setSearchDop] = useState();
  const [modalColumns, setModalColumns] = useState(true);

  useEffect(() => {
    if (organizationUuid && !isModal) {
      getListPersonnelOnOrganizationDop({
        ...dataFilterDop,
        organizationUuid
      });
    }
  }, [getListPersonnelOnOrganizationDop, organizationUuid, isModal]);

  useEffect(() => {
    if (organizationUuid && isModal) {
      getListPersonnelOnOrganization({
        ...dataFilter,
        organizationUuid
      });
    }
  }, [getListPersonnelOnOrganization, organizationUuid, isModal]);

  useEffect(() => {
    if (!isModalVisible && isModal) {
      setSearch(null);
    }
  }, [isModalVisible, isModal]);

  const handleTableChange = useCallback((pagin, filters, sorter) => {
    if (!isModal) {
      getListPersonnelOnOrganizationDop({
        search: searchDop && searchDop.length >= 3,
        offset: (pagin.current - 1) * pagin.pageSize,
        limit: pagin.pageSize,
        organizationUuid
      });
    } else {
      getListPersonnelOnOrganization({
        search: search && search.length >= 3,
        offset: (pagin.current - 1) * pagin.pageSize,
        limit: pagin.pageSize,
        organizationUuid
      });
    }
  }, [
    isModal,
    getListPersonnelOnOrganizationDop,
    searchDop,
    organizationUuid,
    getListPersonnelOnOrganization,
    search
  ]);

  const onExpand = useCallback((expanded, record) => {
    setKeyRowExpanded((values) => {
      let temp = values;
      if (temp.indexOf(record.uuid) === -1) {
        temp.push(record.uuid);
      } else {
        temp = temp.filter((item) => item !== record.uuid);
      }
      return temp;
    });
  }, [setKeyRowExpanded]);

  const renderTable = useMemo(() => (
    // eslint-disable-next-line no-nested-ternary
    <Table columns={isModal ? (modalColumns ? columnsOnModal : columnsOnModalDop) : columnsOnForm}
           rowKey={(record) => record.uuid}
           dataSource={isModal ? data : dataDop}
           pagination={isModal ? pagination : paginationDop}
           onChange={handleTableChange}
           scroll={{ y: '100%' }}
           expandable={{
             expandedRowKeys: keyRowExpanded,
             expandedRowRender: isModal && ((record) => (
               <FormCreatingOrEditPerson record={record} isTable setKeyRowExpanded={setKeyRowExpanded} />
             )),
             onExpand
           }}
    />
  ), [
    isModal,
    modalColumns,
    data,
    pagination,
    handleTableChange,
    dataDop,
    paginationDop,
    onExpand,
    keyRowExpanded,
    setKeyRowExpanded
  ]);

  const onChangeSearch = useCallback((e) => {
    if (isModal) {
      setSearch(e.target.value);
    } else {
      setSearchDop(e.target.value);
    }
    if (e.target.value.length >= 3) {
      if (isModal) {
        getListPersonnelOnOrganization({
          search: e.target.value,
          offset: 0,
          limit: pagination.pageSize,
          organizationUuid,
          isSearch: true
        });
      } else {
        getListPersonnelOnOrganizationDop({
          search: e.target.value,
          offset: 0,
          limit: paginationDop.pageSize,
          organizationUuid,
          isSearch: true
        });
      }
    } else if (isModal) {
      getListPersonnelOnOrganization({
        search: null,
        offset: 0,
        limit: pagination.pageSize,
        organizationUuid,
        isSearch: true
      });
    } else {
      getListPersonnelOnOrganizationDop({
        search: null,
        offset: 0,
        limit: paginationDop.pageSize,
        organizationUuid,
        isSearch: true
      });
    }
  }, [
    isModal,
    getListPersonnelOnOrganization,
    pagination.pageSize,
    organizationUuid,
    getListPersonnelOnOrganizationDop,
    paginationDop.pageSize
  ]);

  const renderSearch = useMemo(() => (
    <Space style={{ marginBottom: 16 }} className={style.header}>
      { !isModal && <h2 style={{ marginBottom: 0 }}>Информация о персонале</h2> }
      <Input placeholder="Поиск"
             prefix={<img src="/images/search.svg" alt="Поиск" />}
             value={isModal ? search : searchDop}
             onChange={onChangeSearch}
      />
      { isModal && (
        <Button type="dashed"
                icon={modalColumns ? <RightOutlined /> : <LeftOutlined />}
                onClick={() => setModalColumns((value) => !value)}
        />
      ) }
    </Space>
  ), [search, onChangeSearch, isModal, modalColumns, searchDop]);

  return (
    <div className={style.containers}>
      { renderSearch }
      <div className="dd-table">
        { renderTable }
      </div>
    </div>
  );
};

export default TablePerson;
