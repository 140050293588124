import { connect } from 'react-redux';
import OrganizationManagement from './OrganizationManagement';
import {
  getListOrganizations,
  setSelectOrganization,
  getCreateOrganizationBiReport
} from '../../store/organization/actions';

const mapStateToProps = ({
  organizationReducer
}) => ({
  listData: organizationReducer.listData,
  selectOrganization: organizationReducer.selectOrganization
});
const mapDispatchToProps = {
  getListOrganizations,
  setSelectOrganization,
  getCreateOrganizationBiReport
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationManagement);
