import React, {
  useEffect
} from 'react';
import {
  Tabs
} from 'antd';
import FormCreateUser from '../../components/FormCreateUser';
import ListUsers from '../../components/ListUsers';
import style from './rolemanagement.module.scss';

const { TabPane } = Tabs;

const RoleManagement = ({
  getListRoles,
  getListOrganizations
}) => {
  useEffect(() => {
    getListRoles();
    getListOrganizations();
  }, [getListRoles, getListOrganizations]);

  return (
    <div className="dd-container">
      <div className={`dd-container-main ${style.containers}`}>
        <div className={style.header}>
          <h2>Управление ролями</h2>
        </div>
        <div className={style.main}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Добавление пользователя" key="1">
              <FormCreateUser />
            </TabPane>
            <TabPane tab="Список пользователей" key="2">
              <div className="dd-table">
                <ListUsers />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default RoleManagement;
