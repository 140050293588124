import { connect } from 'react-redux';
import UserLayout from './UserLayout';

const mapStateToProps = ({
  userReducer
}) => ({
  userReducer
});

export default connect(
  mapStateToProps,
  null
)(UserLayout);
