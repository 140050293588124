import { connect } from 'react-redux';
import UnassignedUsers from './UnassignedUsers';
import { getListUnassignedUsers } from '../../store/unassignedUsers/actions';

const mapStateToProps = ({
  unassignedUsersReducer
}) => ({
  data: unassignedUsersReducer.users.data,
  pagination: unassignedUsersReducer.users.pagination
});
const mapDispatchToProps = {
  getListUnassignedUsers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnassignedUsers);
