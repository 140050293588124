import { connect } from 'react-redux';
import FormCreateUser from './FormCreateUser';
import { createAdmin } from '../../store/roles/actions';

const mapStateToProps = ({
  rolesReducer,
  organizationReducer
}) => ({
  listRoles: rolesReducer.listRoles,
  listData: organizationReducer.listData
});
const mapDispatchToProps = {
  createAdmin
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormCreateUser);
