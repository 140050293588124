/** Получение списка админитраторов */
export const GET_LIST_ADMINS = 'GET_LIST_ADMINS';
export const GET_LIST_ADMINS_SUCCESS = 'GET_LIST_ADMINS_SUCCESS';

/** Получение списка ролей */
export const GET_LIST_ROLES = 'GET_LIST_ROLES';
export const GET_LIST_ROLES_SUCCESS = 'GET_LIST_ROLES_SUCCESS';

/** Создание администратора */
export const CREATE_ADMIN = 'CREATE_ADMIN';

/** Редактирование данных у админа */
export const EDIT_ADMIN = 'EDIT_ADMIN';

/** Удаление пользоватиля */
export const DELETE_ADMIN = 'DELETE_ADMIN';

/** Сброс пароля */
export const RESET_PASSWORD = 'RESET_PASSWORD';
