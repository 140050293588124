import React, {
  useEffect,
  useCallback
} from 'react';
import {
  Form,
  Input,
  Button
} from 'antd';
import { listMenu } from '../../utils/config';
import style from './auth.module.scss';

const Auth = ({
  auth,
  userReducer,
  userCheck,
  history
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (localStorage.getItem('Authorization')) {
      userCheck();
    }
  }, [userCheck]);

  useEffect(() => {
    if (userReducer && userReducer.uuid) {
      history.push(listMenu[userReducer.role.code][userReducer.role.code === 'OWNER' ? 0 : 1].link);
    }
  }, [userReducer, history]);

  const handleFormSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        auth(values);
      })
      .catch((errorInfo) => {

      });
  }, [auth, form]);

  return (
    <div className={style.container}>
      <div className={style.form}>
        <span>ВХОД</span>
        <Form name="basic"
              initialValues={{
                layout: 'vertical'
              }}
              layout="vertical"
              form={form}
              autoComplete="off"
              requiredMark={false}
        >
          <Form.Item label="Логин"
                     name="login"
                     rules={[
                       {
                         required: true,
                         message: 'Введите логин!'
                       }
                     ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Пароль"
                     name="password"
                     rules={[
                       {
                         required: true,
                         message: 'Введите пароль!'
                       }
                     ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary"
                    htmlType="submit"
                    onClick={handleFormSubmit}
                    block
            >
              ВОЙТИ
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Auth;
