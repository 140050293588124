import * as actionsTypes from './types';

export function getStaticInternalVote(payload) {
  return {
    type: actionsTypes.GET_STATIC_INTERNAL_VOTE,
    payload
  };
}
export function getStaticInternalVoteSuccess(payload) {
  return {
    type: actionsTypes.GET_STATIC_INTERNAL_VOTE_SUCCESS,
    payload
  };
}

export function setStartOrUpdateInternalVote(payload) {
  return {
    type: actionsTypes.SET_START_OR_UPDATE_INTERNAL_VOTE,
    payload
  };
}
export function setStopInternalVote(payload) {
  return {
    type: actionsTypes.SET_STOP_INTERNAL_VOTE,
    payload
  };
}

export function setSelectOrganizationOnVote(payload) {
  return {
    type: actionsTypes.SET_SELECT_ORGANIZATION_ON_VOTE,
    payload
  };
}
