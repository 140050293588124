import * as actionTypes from './types';

const initialState = {
  admins: {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0
    }
  },
  listRoles: []
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_LIST_ADMINS_SUCCESS:
      return {
        ...state,
        admins: {
          ...state.admins,
          data: payload.rows,
          pagination: {
            ...state.admins.pagination,
            pageSize: payload.limit,
            current: payload.current,
            total: payload.count
          }
        }
      };
    case actionTypes.GET_LIST_ROLES_SUCCESS:
      return {
        ...state,
        listRoles: payload
      };
    default:
      return state;
  }
};

export default reducer;
