export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_FAIL = 'CREATE_ORGANIZATION_FAIL';

export const GET_LIST_ORGANIZATIONS = 'GET_LIST_ORGANIZATIONS';
export const GET_LIST_ORGANIZATIONS_SUCCESS = 'GET_LIST_ORGANIZATIONS_SUCCESS';
export const GET_LIST_ORGANIZATIONS_FAIL = 'GET_LIST_ORGANIZATIONS_FAIL';

export const GET_INFO_ORGANIZATION = 'GET_INFO_ORGANIZATION';
export const GET_INFO_ORGANIZATION_SUCCESS = 'GET_INFO_ORGANIZATION_SUCCESS';
export const GET_INFO_ORGANIZATION_FAIL = 'GET_INFO_ORGANIZATION_FAIL';

export const EDIT_INFO_ORGANIZATION = 'EDIT_INFO_ORGANIZATION';
export const EDIT_INFO_ORGANIZATION_SUCCESS = 'EDIT_INFO_ORGANIZATION_SUCCESS';
export const EDIT_INFO_ORGANIZATION_FAIL = 'EDIT_INFO_ORGANIZATION_FAIL';

export const DELETE_INFO_ORGANIZATION = 'DELETE_INFO_ORGANIZATION';
export const DELETE_INFO_ORGANIZATION_SUCCESS = 'DELETE_INFO_ORGANIZATION_SUCCESS';
export const DELETE_INFO_ORGANIZATION_FAIL = 'DELETE_INFO_ORGANIZATION_FAIL';

export const GET_STATIC_ORGANIZATION = 'GET_STATIC_ORGANIZATION';
export const GET_STATIC_ORGANIZATION_SUCCESS = 'GET_STATIC_ORGANIZATION_SUCCESS';
export const GET_STATIC_ORGANIZATION_FAIL = 'GET_STATIC_ORGANIZATION_FAIL';

export const SET_SELECT_ORGANIZATION = 'SET_SELECT_ORGANIZATION';

export const GET_LIST_ORGANIZATION_REPORTS = 'GET_LIST_ORGANIZATION_REPORTS';
export const GET_LIST_ORGANIZATION_REPORTS_SUCCESS = 'GET_LIST_ORGANIZATION_REPORTS_SUCCESS';
export const GET_LIST_ORGANIZATION_REPORTS_FAIL = 'GET_LIST_ORGANIZATION_REPORTS_FAIL';

export const GET_CREATE_ORGANIZATION_REPORT = 'GET_CREATE_ORGANIZATION_REPORT';
export const GET_CREATE_ORGANIZATION_BI_REPORT = 'GET_CREATE_ORGANIZATION_BI_REPORT';

/** получение настроек уведомлений */
export const GET_PUSH_ORGANIZATION = 'GET_PUSH_ORGANIZATION';
export const GET_PUSH_ORGANIZATION_SUCCESS = 'GET_PUSH_ORGANIZATION_SUCCESS';
export const EDIT_PUSH_ORGANIZATION = 'EDIT_PUSH_ORGANIZATION';
