import React, {
  useState, useCallback
} from 'react';
import {
  Button,
  Tag,
  Form
} from 'antd';
import ModalThanks from '../ModalThanks';
import style from './tabreasons.module.scss';

const TabReasons = ({
  tagsData,
  setReasons,
  selectOrganization,
  voteForm,
  voteStart,
  setStartOrUpdateInternalVote
}) => {
  const [form] = Form.useForm();
  const [modalTabsData, setModalTabsData] = useState([]);
  const [visible, setVisible] = useState(false);
  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);
  const handleOk = useCallback(() => {
    setReasons(modalTabsData.slice(0));
    // если идет голосование
    if (!voteStart) {
      voteForm.validateFields().then((values) => {
        setStartOrUpdateInternalVote({
          organizationUuid: selectOrganization,
          countLike: values.countLike,
          beginAt: values.date && values.date[0] && values.date[0].valueOf(),
          endAt: values.date && values.date[1] && values.date[1].valueOf(),
          reasons: modalTabsData.slice(0),
          update: true
        });
      });
    }
    setVisible(false);
  }, [
    modalTabsData,
    setReasons,
    voteStart,
    voteForm,
    setStartOrUpdateInternalVote,
    selectOrganization
  ]);

  const onCloseTag = useCallback((e) => {
    setModalTabsData((value) => value.filter((item) => item.title !== e));
  }, []);
  const handleFormSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        setModalTabsData((items) => {
          const temp = items.slice(0);
          temp.push({ title: values.title });
          return temp;
        });
        form.setFieldsValue({
          title: ''
        });
      });
  }, [form, setModalTabsData]);

  const onClickEdit = useCallback(() => {
    setModalTabsData(tagsData.slice(0));
    setVisible(true);
  }, [tagsData]);

  return (
    <div className={style.containers}>
      <div className={style.header}>
        <span>Благодарности</span>
        <Button type="text"
                icon={<img src="/images/edit.svg" alt="Редактировать" />}
                onClick={onClickEdit}
                disabled={!selectOrganization}
        />
      </div>
      <div className={style.main}>
        {
          tagsData.map((item) => (
            <Tag key={`tabReasons_${item.title}`}>{ item.title }</Tag>
          ))
        }
      </div>
      <ModalThanks visible={visible}
                   handleOk={handleOk}
                   handleCancel={handleCancel}
                   form={form}
                   handleFormSubmit={handleFormSubmit}
                   modalTabsData={modalTabsData}
                   onCloseTag={onCloseTag}
      />
    </div>
  );
};

export default TabReasons;
