import React, {
  useState
} from 'react';
import {
  Button,
  Form,
  Input,
  Modal,
  Row,
  Tag
} from 'antd';
import style from './modalthanks.module.scss';

const ModalThanks = ({
  visible,
  handleOk,
  handleCancel,
  form,
  handleFormSubmit,
  modalTabsData,
  onCloseTag
}) => {
  const [value, setValue] = useState(null);
  return (
    <Modal title="Благодарности"
           visible={visible}
           onOk={handleOk}
           onCancel={handleCancel}
           footer={[
             <Button onClick={handleOk}>
               Сохранить
             </Button>
           ]}
           wrapClassName={style.modal}
    >
      <Row>
        <Form name="basic"
              layout="inline"
              form={form}
              autoComplete="off"
              requiredMark={false}
        >
          <Form.Item label="Введите благодарность"
                     name="title"
                     colon
          >
            <Input value={value}
                   onChange={(e) => {
                     const temp = e.target.value ? e.target.value.replace(/[ ]{2,}/gi, '') : null;
                     setValue(temp === '' || temp === ' ' ? null : temp);
                   }}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary"
                    htmlType="submit"
                    onClick={() => {
                      setValue(null);
                      handleFormSubmit();
                    }}
                    disabled={!(value && value.length)}
            >
              Добавить
            </Button>
          </Form.Item>
        </Form>
      </Row>
      <Row className={style.tag}>
        {
          modalTabsData.map((item) => (
            <Tag key={`tabReasonsModal_${item.title}`}
                 closable
                 onClose={() => onCloseTag(item.title)}
            >
              {item.title}
            </Tag>
          ))
        }
      </Row>
    </Modal>
  );
};

export default React.memo(ModalThanks);
