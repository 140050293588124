import { connect } from 'react-redux';
import EventManagement from './EventManagement';
import {
  getDataEventVote,
  getListEventVotes,
  setSelectEvent,
  createEvent,
  deleteEvent,
  setDataEditEvent,
  editEvent
} from '../../store/event/actions';

const mapStateToProps = ({
  eventReducer
}) => ({
  listVotes: eventReducer.listVotes,
  selectEvent: eventReducer.selectEvent,
  dataSelectEvent: eventReducer.dataSelectEvent
});
const mapDispatchToProps = {
  getListEventVotes,
  getDataEventVote,
  setSelectEvent,
  createEvent,
  deleteEvent,
  setDataEditEvent,
  editEvent
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventManagement);
