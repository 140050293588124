import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import Layout from './containers/Layout';
import Auth from './routes/Auth';
import RenderRoute from './containers/RenderRoute';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Layout>
          <Switch>
            <Route path="/" exact component={Auth} />
            <RenderRoute />
            <Route render={() => <h3>Page Not Found!</h3>} />
          </Switch>
        </Layout>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
