import React, {
  useCallback,
  useEffect
} from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row
} from 'antd';
import { MaskedInput } from 'antd-mask-input';
import style from './formcreatingoreditorganization.module.scss';
import DeleteOrganization from '../DeleteOrganization';
import { randomCode } from '../../utils/function';

const FormCreatingOrEditOrganization = ({
  isEdit,
  infoData,
  createOrganization,
  selectOrganization,
  getInfoOrganization,
  editInfoOrganization
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (!isEdit) {
      form.setFieldsValue({
        ...form.getFieldsValue(true),
        inviteCode: randomCode()
      });
    }
  }, [form, isEdit]);

  useEffect(() => {
    if (isEdit && selectOrganization) {
      getInfoOrganization(selectOrganization);
    }
  }, [getInfoOrganization, isEdit, selectOrganization]);

  useEffect(() => {
    if (form && isEdit && infoData) {
      form.setFieldsValue({
        ...infoData
      });
    }
  }, [form, infoData, isEdit]);

  const handleFormSubmit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        createOrganization({
          data: {
            ...values,
            inn: values.inn && values.inn.replace(/[_]/g, ''),
            kpp: values.kpp && values.kpp.replace(/[_]/g, ''),
            ogrn: values.ogrn && values.ogrn.replace(/[_]/g, ''),
            bankAccount: values.bankAccount && values.bankAccount.replace(/[_-]/g, '')
          },
          form
        });
      })
      .catch((errorInfo) => {

      });
  }, [createOrganization, form]);

  const handleFormSubmitEdit = useCallback(() => {
    form
      .validateFields()
      .then((values) => {
        editInfoOrganization({
          data: {
            ...values,
            inn: values.inn && values.inn.replace(/[_]/g, ''),
            kpp: values.kpp && values.kpp.replace(/[_]/g, ''),
            ogrn: values.ogrn && values.ogrn.replace(/[_]/g, ''),
            bankAccount: values.bankAccount && values.bankAccount.replace(/[_-]/g, '')
          },
          uuid: selectOrganization
        });
      })
      .catch((errorInfo) => {

      });
  }, [editInfoOrganization, form, selectOrganization]);

  const onClickRandom = useCallback(() => {
    form.setFieldsValue({
      ...form.getFieldsValue(true),
      inviteCode: randomCode()
    });
  }, [form]);

  return (
    <Form name="basic"
          layout="vertical"
          form={form}
          autoComplete="off"
          requiredMark={false}
    >
      <Row gutter={[40, 8]}>
        <Col md={24} lg={12}>
          <Form.Item label="Название организации"
                     name="title"
                     rules={[
                       {
                         required: true,
                         message: 'Введите название организации'
                       }
                     ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={24} lg={12}>
          <Form.Item label="Почта"
                     name="email"
                     rules={[
                       {
                         type: 'email',
                         message: 'Введите правильно email'
                       }
                     ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={24} lg={12}>
          <Form.Item label="ИНН"
                     name="inn"
                     rules={[
                       {
                         pattern: new RegExp(/\d{10,12}/g),
                         message: 'Введите ИНН',
                         transform: (value) => value && value.replace(/[_-]/g, '')
                       }
                     ]}
          >
            <MaskedInput mask={[
              {
                mask: '0000000000',
                lazy: false
              },
              {
                mask: '000000000000',
                lazy: false
              }
            ]} />
          </Form.Item>
        </Col>
        <Col md={24} lg={12}>
          <Form.Item label="КПП"
                     name="kpp"
                     rules={[
                       {
                         pattern: new RegExp(/\d{9}/g),
                         message: 'Введите КПП',
                         transform: (value) => value && value.replace(/[_-]/g, '')
                       }
                     ]}
          >
            <MaskedInput mask={[
              {
                mask: '000000000',
                lazy: false
              }
            ]} />
          </Form.Item>
        </Col>
        <Col md={24} lg={12}>
          <Form.Item label="ОГРН"
                     name="ogrn"
                     rules={[
                       {
                         pattern: new RegExp(/\d{13}/g),
                         message: 'Введите ОГРН',
                         transform: (value) => value && value.replace(/[_-]/g, '')
                       }
                     ]}
          >
            <MaskedInput mask={[
              {
                mask: '0000000000000',
                lazy: false
              }
            ]} />
          </Form.Item>
        </Col>
        <Col md={24} lg={12}>
          <Form.Item label="Счет организации"
                     name="bankAccount"
                     rules={[
                       {
                         pattern: new RegExp(/\d{20}/g),
                         message: 'Введите счет организации',
                         transform: (value) => value && value.replace(/[_-]/g, '')
                       }
                     ]}
          >
            <MaskedInput mask={[
              {
                mask: '00000-00000-00000-00000',
                lazy: false
              }
            ]} />
          </Form.Item>
        </Col>
        <Col md={24} lg={12}>
          <Form.Item label="Банк обслуживания"
                     name="bank"
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={24} lg={12}>
          <Row gutter={[16, 0]}>
            <Col lg={12}>
              <Form.Item label="Пригласительный код"
                         name="inviteCode"
              >
                <Input />
              </Form.Item>
            </Col>
            <Col lg={12} style={{ paddingTop: 30 }}>
              <Form.Item>
                <Button block
                        onClick={onClickRandom}
                        disabled={isEdit && !selectOrganization}
                >
                  Сгенерировать
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        { !isEdit && (
          <Col lg={{
            span: 12,
            offset: 12
          }}>
            <Form.Item>
              <Button type="primary"
                      htmlType="submit"
                      onClick={handleFormSubmit}
                      block
              >
                Создать организацию
              </Button>
            </Form.Item>
          </Col>
        ) }
        { isEdit && (
          <Col lg={{ span: 24 }} className={style.buttons}>
            <Form.Item>
              <DeleteOrganization />
            </Form.Item>
            <Form.Item style={{ marginLeft: 10, marginRight: 10 }}>
              <Button type="dashed"
                      disabled={!selectOrganization}
              >
                Отменить
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="primary"
                      htmlType="submit"
                      onClick={handleFormSubmitEdit}
                      disabled={!selectOrganization}
              >
                Сохранить
              </Button>
            </Form.Item>
          </Col>
        ) }
      </Row>
    </Form>
  );
};

export default FormCreatingOrEditOrganization;
