import * as actionsTypes from './types';

export function setSelectOrganizationOnPersonnel(payload) {
  return {
    type: actionsTypes.SET_SELECT_ORGANIZATION_ON_PERSONNEL,
    payload
  };
}

export function getListPersonnelOnOrganization(payload) {
  return {
    type: actionsTypes.GET_LIST_PERSONNEL_ON_ORGANIZATION,
    payload
  };
}
export function getListPersonnelOnOrganizationSuccess(payload) {
  return {
    type: actionsTypes.GET_LIST_PERSONNEL_ON_ORGANIZATION_SUCCESS,
    payload
  };
}

export function getListPersonnelOnOrganizationDop(payload) {
  return {
    type: actionsTypes.GET_LIST_PERSONNEL_ON_ORGANIZATION_DOP,
    payload
  };
}
export function getListPersonnelOnOrganizationDopSuccess(payload) {
  return {
    type: actionsTypes.GET_LIST_PERSONNEL_ON_ORGANIZATION_DOP_SUCCESS,
    payload
  };
}

export function createPersonnelOnOrganization(payload) {
  return {
    type: actionsTypes.CREATE_PERSONNEL_ON_ORGANIZATION,
    payload
  };
}

export function editPersonnelOnOrganization(payload) {
  return {
    type: actionsTypes.EDIT_PERSONNEL_ON_ORGANIZATION,
    payload
  };
}

export function deletePersonnelOnOrganization(payload) {
  return {
    type: actionsTypes.DELETE_PERSONNEL_ON_ORGANIZATION,
    payload
  };
}

export function openModalPersonnelOnOrganization(payload) {
  return {
    type: actionsTypes.OPEN_MODAL_PERSONNEL_ON_ORGANIZATION,
    payload
  };
}
export function closeModalPersonnelOnOrganization(payload) {
  return {
    type: actionsTypes.CLOSE_MODAL_PERSONNEL_ON_ORGANIZATION,
    payload
  };
}

export function openModalCreatePerson(payload) {
  return {
    type: actionsTypes.OPEN_MODAL_CREATE_PERSON,
    payload
  };
}
export function closeModalCreatePerson(payload) {
  return {
    type: actionsTypes.CLOSE_MODAL_CREATE_PERSON,
    payload
  };
}

export function importPersonUploadCsvFile(payload) {
  return {
    type: actionsTypes.IMPORT_PERSON_UPLOAD_CSV_FILE,
    payload
  };
}

export function acceptAllPersonsInOrganization(payload) {
  return {
    type: actionsTypes.ACCEPT_ALL_PERSONS_IN_ORGANIZATION,
    payload
  };
}
