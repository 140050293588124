import * as actionTypes from './types';

const initialState = {
  visible: 0
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.POPUP_LOADER_OPEN:
      return {
        ...state,
        visible: state.visible + 1
      };
    case actionTypes.POPUP_LOADER_CLOSE:
      return {
        ...state,
        visible: state.visible - 1 < 0 ? 0 : state.visible - 1
      };
    default:
      return state;
  }
};

export default reducer;
