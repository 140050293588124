import React from 'react';
import {
  Modal,
  Button,
  Checkbox,
  Space,
  Select,
  Input,
  Table
} from 'antd';
import style from './modalcandidates.module.scss';

const ModalCandidates = ({
  visible,
  handleCancel,
  columns,
  listDataOrganization,
  onChangeOrganize,
  data,
  pagination,
  handleTableChangeModal,
  searchModal,
  setSearchModal,
  onChangeSelectCandidates,
  selectedRowKeys,
  onSave
}) => (
  <Modal visible={visible}
         wrapClassName={style.modal}
         onCancel={handleCancel}
         footer={[
           <Button onClick={onSave}>Сохранить</Button>,
           <Checkbox>Выбрать всех кандидатов</Checkbox>
         ]}
  >
    <div>
      {/* поисковый заголовок */}
      <Space style={{ marginBottom: 5 }} className="dd-header-space">
        <div className="dd-header-text"><h2>Выбрать кандидатов</h2></div>
        <div className="dd-header-input">
          <Space className="dd-header-input-div">
            <Select placeholder="Выберите организацию"
                    options={listDataOrganization}
                    fieldNames={{ label: 'title', value: 'uuid' }}
                    onChange={onChangeOrganize}
                    allowClear
            />
            <Input placeholder="Поиск"
                   prefix={<img src="/images/search.svg" alt="Поиск" />}
                   onChange={(e) => setSearchModal(e.target.value)}
                   value={searchModal}
            />
          </Space>
        </div>
      </Space>
      <div className="dd-table">
        {/* таблица */}
        <Table scroll={{ y: '100%' }}
               columns={columns}
               dataSource={data}
               pagination={pagination}
               rowKey={(record) => record.uuid}
               onChange={handleTableChangeModal}
               rowSelection={{
                 type: 'checkbox',
                 onChange: onChangeSelectCandidates,
                 selectedRowKeys
               }}
        />
      </div>
    </div>
  </Modal>
);

export default React.memo(ModalCandidates);
