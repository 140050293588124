import { Button, Modal } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import React, { useCallback } from 'react';

const { confirm } = Modal;
const ButtonUserUnassignedDelete = ({ deleteUnassignedUser, record }) => {
    const onClick = useCallback(() => {
        confirm({
            title: 'Удаление нераспределенного пользователя',
            icon: <DeleteFilled />,
            content: 'Вы действительно хотите удалить нераспределенного пользовтаеля?',
            onOk() {
                deleteUnassignedUser({
                    userUuid: record.uuid
                });
            }
        });
    }, [deleteUnassignedUser, record]);
    return (
      <Button
        shape="circle"
        type="primary"
        style={{ backgroundColor: 'red' }}
        icon={<DeleteFilled />}
        onClick={onClick} />
    );
};

export default ButtonUserUnassignedDelete;
