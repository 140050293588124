import React, {
  useState,
  useCallback
} from 'react';
import { Button } from 'antd';
import style from './buttonresetpassword.module.scss';

const ButtonResetPassword = ({
  resetPassword,
  uuid
}) => {
  const [isPress, setIsPress] = useState(false);
  const onClick = useCallback(() => {
    resetPassword({
      uuid,
      setIsPress
    });
  }, [resetPassword, uuid, setIsPress]);

  return (
    <div className={style.containers}>
      {
        !isPress ? (
          <Button type="link"
                  onClick={onClick}
                  size="small"
                  className={style.button}
          >
            Сбросить
          </Button>
        ) : (
          <img alt="Пароль сброшен" src="/images/check-circle.svg" />
        )
      }
    </div>
  );
};

export default ButtonResetPassword;
