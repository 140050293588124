import * as actionTypes from './types';

const initialState = {
  users: {
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
      search: null
    },
    loading: false
  },
  modal: {
    isModalVisible: false,
    userUuid: null,
    organizations: {
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      loading: false
    }
  }
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.GET_LIST_UNASSIGNED_USERS:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true
        }
      };
    case actionTypes.GET_LIST_UNASSIGNED_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          data: payload.rows,
          pagination: {
            ...state.users.pagination,
            pageSize: payload.limit,
            current: payload.current,
            total: payload.count,
            search: payload.search
          },
          loading: false
        }
      };
    case actionTypes.GET_LIST_UNASSIGNED_USERS_FAIL:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false
        }
      };
    case actionTypes.MODAL_USER_UNASSIGNED_OPEN:
      return {
        ...state,
        modal: {
          ...state.modal,
          isModalVisible: true,
          userUuid: payload.userUuid
        }
      };
    case actionTypes.MODAL_USER_UNASSIGNED_CLOSE:
      return {
        ...state,
        modal: {
          ...state.modal,
          isModalVisible: false,
          userUuid: null
        }
      };
    case actionTypes.GET_LIST_UNASSIGNED_USERS_ORGANIZATIONS:
      return {
        ...state,
        modal: {
          ...state.modal,
          organizations: {
            ...state.modal.organizations,
            loading: true
          }
        }
      };
    case actionTypes.GET_LIST_UNASSIGNED_USERS_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        modal: {
          ...state.modal,
          organizations: {
            ...state.modal.organizations,
            data: payload.rows,
            pagination: {
              ...state.modal.organizations.pagination,
              pageSize: payload.limit,
              current: payload.current,
              total: payload.count
            },
            loading: false
          }
        }
      };
    case actionTypes.GET_LIST_UNASSIGNED_USERS_ORGANIZATIONS_FAIL:
      return {
        ...state,
        modal: {
          ...state.modal,
          organizations: {
            ...state.modal.organizations,
            loading: false
          }
        }
      };
    default:
      return state;
  }
};

export default reducer;
