import React, { useMemo } from 'react';
import { Tooltip } from 'antd';
import { useSize } from 'ahooks';
import { NavLink } from 'react-router-dom';
import { listMenu } from '../../utils/config';
import style from './menu.module.scss';

const Menu = ({
  role
}) => {
  const size = useSize(document.querySelector('body'));

  const renderLink = useMemo(() => (
    role && listMenu[role.code].map((item) => (
      <div className={item.disable ? style.disable : ''} key={`div_${item.link}`}>
        {
          size?.width && size?.width > 1000
            ? (
              <NavLink to={`/${item.link}`} key={`nav_${item.link}`}>
                <img src={`/images/icon-menu/${item.link}.svg`} alt={item.text} />
                {item.text}
              </NavLink>
            )
            : (
              <Tooltip placement="right" title={item.text}>
                <NavLink to={`/${item.link}`} key={`nav_${item.link}`}>
                  <img src={`/images/icon-menu/${item.link}.svg`} alt={item.text} />
                </NavLink>
              </Tooltip>
            )
        }
      </div>
    ))
  ), [role, size]);

  return (
    <div className={style.container}>
      <div className={style.containerMenu}>
        <div>
          {renderLink}
        </div>
      </div>
    </div>
  );
};

export default Menu;
