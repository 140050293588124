import * as actionsType from './types';

export function popupLoaderOpen(payload) {
  return {
    type: actionsType.POPUP_LOADER_OPEN,
    payload
  };
}

export function popupLoaderClose(payload) {
  return {
    type: actionsType.POPUP_LOADER_CLOSE,
    payload
  };
}
