import React, {
  useEffect,
  useState,
  useCallback
} from 'react';
import { Checkbox, Row, Col } from 'antd';

const NotificationManagement = ({
  selectOrganization,
  pushData,
  getPushOrganization,
  editPushOrganization,
  selectEvent,
  pushDataEvent,
  getPushEvent,
  isEvent,
  editPushEvent
}) => {
  const [values, setValues] = useState([]);
  useEffect(() => {
    if (!isEvent) {
      if (selectOrganization) {
        getPushOrganization({ uuid: selectOrganization });
      }
    } else if (selectEvent) {
      getPushEvent({ uuid: selectEvent });
    }
  }, [getPushEvent, getPushOrganization, isEvent, selectEvent, selectOrganization]);
  useEffect(() => {
    if (!isEvent) {
      if (pushData) {
        setValues(Object.keys(pushData)
          .filter((value) => pushData[value]));
      }
    } else if (pushDataEvent) {
      setValues(Object.keys(pushDataEvent)
        .filter((value) => pushDataEvent[value]));
    }
  }, [isEvent, pushData, pushDataEvent]);
  const onChange = useCallback((value) => {
    const temp = {
      pushNewCard: false,
      pushBeginVote: false,
      pushEndVote: false,
      pushNewVoter: false
    };
    value.forEach((key) => {
      temp[key] = true;
    });
    if (!isEvent) {
      editPushOrganization({
        uuid: selectOrganization,
        data: {
          ...temp
        }
      });
    } else {
      editPushEvent({
        uuid: selectEvent,
        data: {
          ...temp
        }
      });
    }
  }, [editPushEvent, editPushOrganization, isEvent, selectEvent, selectOrganization]);
  return (
    <>
      <h2 style={{ display: isEvent ? 'none' : 'block' }}>Управление уведомлениями</h2>
      <p style={{ display: isEvent ? 'none' : 'block' }}>Включить отправку уведомлений:</p>
      <Checkbox.Group style={{ width: '100%' }} value={values} onChange={onChange}>
        <Row>
          <Col span={24}>
            <Checkbox value="pushNewCard">О получении новой карточки благодарности</Checkbox>
          </Col>
          <Col span={24}>
            <Checkbox value="pushBeginVote">О начале голосования (мероприятия)</Checkbox>
          </Col>
          <Col span={24}>
            <Checkbox value="pushEndVote">О завершении голосования (мероприятия)</Checkbox>
          </Col>
          <Col span={24}>
            <Checkbox value="pushNewVoter">При добавлении избирателя (кандидата)</Checkbox>
          </Col>
        </Row>
      </Checkbox.Group>
    </>
  );
};

export default NotificationManagement;
