import { connect } from 'react-redux';
import RoleManagement from './RoleManagement';
import { getListRoles } from '../../store/roles/actions';
import { getListOrganizations } from '../../store/organization/actions';

const mapStateToProps = () => {};
const mapDispatchToProps = {
  getListRoles,
  getListOrganizations
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleManagement);
