import React, {
  useCallback,
  useEffect,
  useState
} from 'react';
import { Table } from 'antd';
import {
  renderFIOAndRole,
  renderHeaderTitleTable,
  renderLoginEmail,
  renderOrganization
} from '../../utils/function';
import FormEditUserOnListUser from '../FormEditUserOnListUser';
import ButtonResetPassword from '../ButtonResetPassword';

const columns = [
  {
    width: 180,
    title: renderHeaderTitleTable('ФИО', 'Роль'),
    render: renderFIOAndRole
  },
  {
    width: 200,
    title: renderHeaderTitleTable('Логин', 'Эл.почта'),
    render: renderLoginEmail
  },
  {
    width: 250,
    title: renderHeaderTitleTable('Организация'),
    render: renderOrganization
  },
  {
    title: renderHeaderTitleTable('Сброс пароль'),
    key: 'operation',
    width: 170,
    fixed: 'right',
    render: (text, record, index) => <ButtonResetPassword uuid={record.uuid} />
  }
];
const dataFilter = {
  offset: 0,
  limit: 10
};

const ListUsers = ({
  data,
  pagination,
  getListAdmins
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  useEffect(() => {
    getListAdmins(dataFilter);
  }, [getListAdmins]);

  const handleTableChange = useCallback((pagin, filters, sorter) => {
    getListAdmins({
      offset: (pagin.current - 1) * pagin.pageSize,
      limit: pagin.pageSize
    });
  }, [getListAdmins]);

  const onExpand = useCallback((expanded, record) => {
    setExpandedRowKeys((values) => (values.length > 0 && values[0] === record.uuid ? [] : [record.uuid]));
  }, []);

  return (
    <Table columns={columns}
           rowKey={(record) => record.uuid}
           expandable={{
             expandedRowRender: (record) => (
               <FormEditUserOnListUser record={record} setExpandedRowKeys={setExpandedRowKeys} />),
             expandedRowKeys,
             onExpand
           }}
           dataSource={data}
           pagination={pagination}
           scroll={{ y: '100%' }}
           onChange={handleTableChange}
    />
  );
};

export default ListUsers;
