import React, {
  useCallback
} from 'react';
import { Modal, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const DeleteEventUser = ({
  record,
  deleteUserOnEvent
}) => {
  const onClick = useCallback(() => {
    confirm({
      title: 'Удаления пользователя из мероприятия?',
      onOk() {
        deleteUserOnEvent({ uuid: record.uuid });
      }
    });
  }, [record, deleteUserOnEvent]);
  return (
    <Button type="link"
            onClick={onClick}
            icon={<DeleteOutlined />}
    />
  );
};

export default DeleteEventUser;
