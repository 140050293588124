export const GET_LIST_UNASSIGNED_USERS = 'GET_LIST_UNASSIGNED_USERS';
export const GET_LIST_UNASSIGNED_USERS_SUCCESS = 'GET_LIST_UNASSIGNED_USERS_SUCCESS';
export const GET_LIST_UNASSIGNED_USERS_FAIL = 'GET_LIST_UNASSIGNED_USERS_FAIL';

export const MODAL_USER_UNASSIGNED_OPEN = 'MODAL_USER_UNASSIGNED_OPEN';
export const MODAL_USER_UNASSIGNED_CLOSE = 'MODAL_USER_UNASSIGNED_CLOSE';

export const GET_LIST_UNASSIGNED_USERS_ORGANIZATIONS = 'GET_LIST_UNASSIGNED_USERS_ORGANIZATIONS';
export const GET_LIST_UNASSIGNED_USERS_ORGANIZATIONS_SUCCESS = 'GET_LIST_UNASSIGNED_USERS_ORGANIZATIONS_SUCCESS';
export const GET_LIST_UNASSIGNED_USERS_ORGANIZATIONS_FAIL = 'GET_LIST_UNASSIGNED_USERS_ORGANIZATIONS_FAIL';

export const OPERATION_UNASSIGNED_USER_ON_ORGANIZATION = 'OPERATION_UNASSIGNED_USER_ON_ORGANIZATION';

export const DELETE_UNASSIGNED_USER = 'DELETE_UNASSIGNED_USER';
export const DELETE_UNASSIGNED_USER_SUCCESS = 'DELETE_UNASSIGNED_USER_SUCCESS';
export const DELETE_UNASSIGNED_USER_FAIL = 'DELETE_UNASSIGNED_USER_FAIL';
